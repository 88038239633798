import { Typography, Box } from '@mui/material';
import ModalCom from '@components/modals/ModalCom';
import { ModalH6Text } from '@styles/modal/SModal';

import { ButtonGradientDark } from '@styles/shared/SButtons';
import { Svgs } from '@utils/svgs';
import { TCreateWTIListingInitialValues } from '@typescript/TModalFormik';
import { useFormikContext } from 'formik';

interface Props {
  errorModal: boolean;
  closeErrorModal: () => void;
}

function WtiErrorModal({ errorModal, closeErrorModal }: Props) {
  const { resetForm } = useFormikContext<TCreateWTIListingInitialValues>();
  return (
    <ModalCom
      open={errorModal}
      onClose={() => {
        closeErrorModal();
        resetForm();
      }}
    >
      <Box
        sx={{
          p: '36px ',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ maxWidth: '76px', width: '100%' }}>
          <Svgs.Error style={{ marginBottom: '24px' }} />
        </Box>
        <Typography variant="h4" sx={{ mb: '10px' }}>
          Something went wrong...
        </Typography>
        <ModalH6Text sx={{ mb: '12px' }}>
          Please try investing again
        </ModalH6Text>

        <ButtonGradientDark
          fullWidth
          sx={{ mb: '12px' }}
          onClick={() => {
            closeErrorModal();
            resetForm();
          }}
        >
          Try again
        </ButtonGradientDark>
      </Box>
    </ModalCom>
  );
}
export default WtiErrorModal;
