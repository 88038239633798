import { Typography, Box } from '@mui/material';
import { formatNr } from '@utils/functions';
import ModalCom from '@components/modals/ModalCom';
import { ModalDetailsBox, ModalH6Text } from '@styles/modal/SModal';
import useStyles from '@hooks/useStyles';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import { useNavigate } from 'react-router-dom';
import { Svgs } from '@utils/svgs';
import useUtils from '@hooks/useUtils';
import { TProject } from '@typescript/models/Project.model';

export type TxSummary = {
  hash: string;
  tokenPrice: number;
  totalCost: number;
  tokenAmount: number;
  project: TProject;
};

interface Props {
  open: boolean;
  onClose: () => void;
  txSummary: TxSummary;
}

function TradeTxSuccessModal({ open, onClose, txSummary }: Props) {
  const navigate = useNavigate();

  const { border1pxPrimaryLight } = useStyles();

  const { getBscTxLink } = useUtils();

  return (
    <ModalCom open={open} onClose={onClose}>
      <Box
        sx={{
          p: '36px 68px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ maxWidth: '76px', width: '100%' }}>
          <Svgs.Success style={{ marginBottom: '24px' }} />
        </Box>
        <Typography variant="h4" sx={{ mb: '10px' }}>
          Your transaction has been successful!
        </Typography>
        <ModalDetailsBox sx={{ mb: '36px' }}>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                borderRight: border1pxPrimaryLight,
                p: '15px',
                minWidth: { xs: '110px', sm: '124px' },
              }}
            >
              <ModalH6Text>Project</ModalH6Text>
              <ModalH6Text>Token Amount</ModalH6Text>
              <ModalH6Text>Token Price</ModalH6Text>
              <ModalH6Text>Total Cost</ModalH6Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                gap: '10px',
                p: '15px',
              }}
            >
              <Box
                sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
              >
                <img
                  src={txSummary.project.image}
                  style={{ borderRadius: '6px' }}
                  width={20}
                  height={20}
                  alt="project"
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {txSummary.project.name}
                </Typography>
              </Box>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '13px', xsm: '15px' },
                }}
              >
                {formatNr(txSummary.tokenAmount)}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '13px', xsm: '15px' },
                }}
              >
                {formatNr(txSummary.tokenPrice, true)}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '13px', xsm: '15px' },
                }}
              >
                {formatNr(txSummary.totalCost, true)}
              </Typography>
            </Box>
          </Box>
        </ModalDetailsBox>
        <ButtonGradientDark
          fullWidth
          sx={{ mb: '12px' }}
          onClick={() => {
            navigate('/portfolio');
            onClose();
          }}
        >
          View Portfolio
        </ButtonGradientDark>
        <ButtonGradientDark
          fullWidth
          sx={{ mb: '12px' }}
          onClick={() => {
            const link = getBscTxLink(txSummary.hash);
            window.open(link, '_blank');
          }}
        >
          View on BscScan
        </ButtonGradientDark>
      </Box>
    </ModalCom>
  );
}
export default TradeTxSuccessModal;
