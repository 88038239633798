import useStyles from '@hooks/useStyles';
import { Typography, Box, Tooltip, InputAdornment } from '@mui/material';
import {
  HeaderModal,
  StepsIndicator,
  CloseBtn,
  ModalH6Text,
  FieldStyled,
  QuestionMarkStyled,
  ModalFooter,
  ErrorListTooltip,
  ErrorListItemTooltip,
} from '@styles/modal/SModal';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import { TCreateInvestmentEthProjectInitialValues } from '@typescript/TModalFormik';
import {
  formatModalDecimals,
  formatNr,
} from '@utils/functions';
import { useFormikContext } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { TProject } from '@typescript/models/Project.model';
import { Tier, TokenTier } from '@typescript/TTier';
import ModalCom from '../ModalCom';

interface Props {
  project: TProject;
  balance: number;
  holdingTier: TokenTier | null;
  nftTier: Tier | null;
  next: () => void;
  onClose: () => void;
  maxInvestment: number;
}

function InvestmentEthStep1Modal({
  project,
  next,
  onClose,
  holdingTier,
  nftTier,
  balance = 0,
  maxInvestment,
}: Props) {
  const {
    values,
    handleBlur,
    touched,
    errors,
    setValues,
    setTouched,
    resetForm,
  } = useFormikContext<TCreateInvestmentEthProjectInitialValues>();

  const invalidTotalCost = Boolean(errors.totalCost && touched.totalCost);
  const validTotalCost = Boolean(!errors.totalCost && touched.totalCost);

  const shouldDisplayError = invalidTotalCost;

  const isWTIStep1Valid = validTotalCost;

  const { border1pxPrimaryLight } = useStyles();

  const handleChangeTotalCost = (totalCost: string) => {
    setTouched({ ...touched, totalCost: true });

    if (totalCost === '') {
      setValues((prev) => ({
        ...prev,
        totalCost: '',
      }));
      return;
    }
    const totalCostFormatted = formatModalDecimals(totalCost);
    setValues((prev) => ({
      ...prev,
      totalCost: totalCostFormatted,
    }));
  };

  const handleNextStep = () => {
    next();
  };

  return (
    <ModalCom open onClose={onClose}>
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            New Investment
          </Typography>
          <StepsIndicator>Step 1 of 2 </StepsIndicator>
          <CloseBtn
            onClick={() => {
              onClose();
              resetForm();
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>
        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <Box>
              <ModalH6Text>Project Name</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                }}
              >
                <img
                  src={project.image}
                  style={{ borderRadius: '6px' }}
                  width={24}
                  height={24}
                  alt="project"
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: '13px', xsm: '15px' },
                    fontWeight: 400,
                    paddingLeft: '0.5rem',
                  }}
                >
                  {project.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Currently Raised</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '10px',
                }}
              >
                <Tooltip
                  title={`${project.investmentAmount} / ${project.fundraisingTarget}`}
                  placement="right-end"
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 400,
                      paddingRight: '0.3rem',
                      fontSize: { xs: '13px', xsm: '15px' },
                    }}
                  >
                    {formatNr(project.investmentAmount, undefined, 0)}/
                    {formatNr(project.fundraisingTarget, undefined, 0)}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: border1pxPrimaryLight,
            display: 'flex',
            padding: '15px',
            gap: '15px',
          }}
        >
          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <ModalH6Text sx={{ fontSize: '14px' }}>Min</ModalH6Text>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 400,
                fontSize: { xs: '14px' },
                lineHeight: 'normal',
              }}
            >
              {formatNr(!maxInvestment ? 0 : project.minInvestment, false)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <ModalH6Text sx={{ fontSize: '14px' }}> Max</ModalH6Text>

            <Typography
              variant="h4"
              sx={{
                fontWeight: 400,
                fontSize: { xs: '14px' },
                lineHeight: 'normal',
              }}
            >
              {formatNr(maxInvestment, false)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <ModalH6Text sx={{ fontSize: '14px' }}>Stake Tier</ModalH6Text>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 400,
                fontSize: { xs: '14px' },
                lineHeight: 'normal',
              }}
            >
              {holdingTier?.name ? holdingTier?.name : '-'}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <ModalH6Text sx={{ fontSize: '14px' }}>NFT Tier</ModalH6Text>

            <Typography
              variant="h4"
              sx={{
                fontWeight: 400,
                fontSize: { xs: '14px' },
                lineHeight: 'normal',
              }}
            >
              {nftTier?.name ? nftTier?.name : '-'}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            paddingLeft: '15px',
            paddingRight: '15px',
            borderTop: border1pxPrimaryLight,
          }}
        >
          <Box
            sx={{
              paddingTop: { xs: '1rem', xsm: '18px' },
              display: 'flex',
              gap: '12px',
            }}
          >
            <FieldStyled
              id="totalCost"
              name="totalCost"
              label="Investment"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      marginBottom: '2px',
                      marginLeft: '0px',
                      '& .MuiTypography-root': { fontSize: '15px' },
                    }}
                  >
                    LGV
                  </InputAdornment>
                ),
              }}
              type="number"
              sx={{ flex: 1 }}
              value={values.totalCost}
              onBlur={handleBlur}
              onChange={(event) => {
                const { value } = event.target;
                if (value.length <= 10) {
                  handleChangeTotalCost(value);
                }
              }}
              error={invalidTotalCost}
              placeholder="0"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </Box>

        <Box sx={{ p: '15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ModalH6Text mr={1}>Pay in</ModalH6Text>
            <Tooltip title="Only LEGION token available" placement="right-end">
              <QuestionMarkStyled />
            </Tooltip>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '0.7rem',
              paddingTop: '1rem',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ButtonGradientDark variant="contained">
                LEGION
              </ButtonGradientDark>
              <Typography variant="h6" mt="8px">
                {formatNr(balance)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <ModalFooter>
          <ButtonGradientDark
            fullWidth
            onClick={handleNextStep}
            disabled={!isWTIStep1Valid}
          >
            Next step
          </ButtonGradientDark>
        </ModalFooter>
        {shouldDisplayError && (
          <ErrorListTooltip>
            {invalidTotalCost && (
              <ErrorListItemTooltip>{errors.totalCost}</ErrorListItemTooltip>
            )}
          </ErrorListTooltip>
        )}
      </>
    </ModalCom>
  );
}

export default InvestmentEthStep1Modal;
