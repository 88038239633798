import { Typography, Box, Link, Tooltip } from '@mui/material';
import { TListing } from '@typescript/models/Listing.model';
import { TCoin } from '@typescript/TCoin';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useEffect, useState } from 'react';
import useUtils from '@hooks/useUtils';
import { ButtonGradientDark, BuyGradientDark } from '@styles/shared/SButtons';
import {
  CloseBtn,
  CurrentPriceBox,
  ErrorListItemTooltip,
  ErrorListTooltip,
  HeaderModal,
  ModalFooter,
  ModalH6Text,
  QuestionMarkStyled,
} from '@styles/modal/SModal';
import { BuyConfirmationValues } from '@formik/modal/ModalInitialData';
import { BuyConfirmationValidationSchema } from '@formik/modal/ModalValidation';
import ModalCom from '@components/modals/ModalCom';
import {
  calculateProfitLoss,
  cryptoFormat,
  formatNr,
  sliceWalletAddress,
  toRem,
} from '@utils/functions';
import { border1pxPrimaryLight } from '@styles/shared/SCss';
import useUser from '@hooks/useUser';
import { calculateAmountAfterFee } from '@utils/calculateAmountAfterFee';
import useTransfer from '@hooks/useTransfer';
import { MetamaskError } from '@utils/errors';
import { TransferType } from '@constants/CTransfer';

interface Props {
  listing: TListing;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: (message: string) => void;
  onTxInitialization: () => void;
  beforeTxConfirmation: (hash: string) => void;
}

function BuyWTSModal({
  listing,
  onClose,
  open,
  onSuccess,
  onError,
  beforeTxConfirmation,
  onTxInitialization,
}: Props) {
  const { userCoins } = useUser();

  const { wtsTransfer } = useTransfer();

  const { getBscAddressLink, transferType } = useUtils();

  const [isLoading, setIsLoading] = useState(false);

  const { totalPrice: totalPriceWitFee, feeOfListingPrice } =
    calculateAmountAfterFee(listing.price);

  const totalPrice =
    transferType === TransferType.OTC_TRANSFER_CONTRACT
      ? totalPriceWitFee
      : listing.price;

  const feeText =
    transferType === TransferType.OTC_TRANSFER_CONTRACT
      ? `${formatNr(feeOfListingPrice, true)} Fee`
      : '';

  const [listingCoinsData, setListingCoinsData] = useState<TCoin[]>([]);

  const setListingAvailableCoins = useCallback(() => {
    const listingCoinData: TCoin[] = [];
    listing.coins.forEach((listingCoin) => {
      if (userCoins) {
        const foundedCoin = userCoins.find(
          (userCoin) => userCoin.name === listingCoin,
        );
        if (foundedCoin) {
          listingCoinData.push(foundedCoin);
        }
      }
    });
    setListingCoinsData(listingCoinData);
  }, [listing.coins, userCoins]);

  const getModalAskingPrice = (currentListing: TListing) => {
    const style = {
      fontWeight: 400,
      paddingTop: { xs: '0.5rem', xsm: '1rem' },
      fontSize: { xs: '14px', xsm: '16px' },
    };

    return (
      <Tooltip title={currentListing.tokenPrice} placement="top-start">
        <Box sx={{ display: 'flex', gap: 1 }}>
          {cryptoFormat(currentListing.tokenPrice, style)}
          {calculateProfitLoss(
            currentListing.project.currentPrice,
            currentListing.tokenPrice,
            style,
          )}
        </Box>
      </Tooltip>
    );
  };

  const formik = useFormik({
    initialValues: BuyConfirmationValues,
    validationSchema: BuyConfirmationValidationSchema(totalPrice || 0),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.selectedCoin !== '') {
        try {
          setIsLoading(true);
          onTxInitialization();
          await wtsTransfer(values.selectedCoin, listing, beforeTxConfirmation);
          onSuccess();
          setIsLoading(false);
        } catch (e) {
          const error = e as MetamaskError;
          if (error?.code === 4001) {
            onError('Transaction rejected.');
          } else {
            onError('Something went wrong..');
          }
          setIsLoading(false);
          throw error;
        }
      }
    },
  });

  const { values, errors, touched, handleSubmit, setValues, setTouched } =
    formik;

  const invalidCoins = Boolean(errors.selectedCoin && touched.selectedCoin);
  const invalidFunds = Boolean(errors.coinValue && touched.coinValue);

  const validCoins = Boolean(!errors.selectedCoin && touched.selectedCoin);
  const validFunds = Boolean(!errors.coinValue && touched.coinValue);

  const shouldDisplayError = invalidCoins || invalidFunds;

  const isValidBuyWTSModal = validCoins && validFunds;

  const handleSelectedCoin = (coin: TCoin) => {
    setTouched({ coinValue: true, selectedCoin: true });
    setValues((prev) => ({
      ...prev,
      selectedCoin: coin.name,
      coinValue: coin.balance,
    }));
  };

  useEffect(() => {
    setListingAvailableCoins();
  }, [setListingAvailableCoins]);

  return (
    <ModalCom open={open} onClose={onClose}>
      <Box>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Buy
          </Typography>
          <CloseBtn onClick={onClose}>
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>

        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <Box>
              <ModalH6Text>Project name</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                }}
              >
                <img
                  src={listing.project.image}
                  style={{ borderRadius: '6px' }}
                  width={24}
                  height={24}
                  alt="project"
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: '13px', xsm: '15px' },
                    fontWeight: 400,
                    paddingLeft: '0.5rem',
                  }}
                >
                  {listing.project.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Current Price</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    paddingRight: '0.3rem',
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  <CurrentPriceBox>
                    {cryptoFormat(listing.project.currentPrice)}
                  </CurrentPriceBox>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <ModalH6Text>Asking Price</ModalH6Text>
              <CurrentPriceBox>{getModalAskingPrice(listing)}</CurrentPriceBox>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
            borderBottom: border1pxPrimaryLight,
            borderTop: border1pxPrimaryLight,
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <ModalH6Text sx={{ marginBottom: '8px' }}>Listing User</ModalH6Text>
            <Link
              href={getBscAddressLink(listing.user.ethAddress)}
              target="_blank"
              sx={{ fontSize: { xs: '13px', xsm: '15px' } }}
              aria-label="wallet address"
            >
              {sliceWalletAddress(listing.user.ethAddress)}
            </Link>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Token Amount</ModalH6Text>
              <Tooltip title={listing.amount} placement="left-start">
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '14px', xsm: '15px' },
                    paddingTop: '10px',
                  }}
                >
                  {formatNr(listing.amount, undefined, 0)}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box>
              <ModalH6Text>Total Cost</ModalH6Text>
              <Box display="flex" gap="0.3rem">
                <Tooltip title={totalPrice} placement="left-start">
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 400,
                      fontSize: { xs: '13px', xsm: '15px' },
                      paddingTop: '10px',
                    }}
                  >
                    {formatNr(totalPrice, true)}
                  </Typography>
                </Tooltip>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                    paddingTop: '10px',
                  }}
                >
                  {feeText}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ p: '15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ModalH6Text mr={1}>Pay in</ModalH6Text>
            <Tooltip
              title="You can choose one stable coin"
              placement="right-end"
            >
              <QuestionMarkStyled />
            </Tooltip>
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '0.7rem',
              paddingTop: '11px',
            }}
          >
            {listingCoinsData.map((coin) => (
              <Box
                key={coin.name}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ButtonGradientDark
                  variant={
                    values.selectedCoin === coin.name ? 'contained' : 'outlined'
                  }
                  onClick={() => handleSelectedCoin(coin)}
                  fullWidth
                  disableRipple
                >
                  {coin.name}
                </ButtonGradientDark>

                <Typography variant="h6" mt="8px">
                  {formatNr(coin.balance, true)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <ModalFooter>
          <BuyGradientDark
            onClick={() => handleSubmit()}
            sx={{
              padding: {
                xs: `${toRem(18)} ${toRem(16)}`,
                sm: `${toRem(11)} ${toRem(16)}`,
              },
            }}
            disabled={!isValidBuyWTSModal || isLoading}
          >
            Buy
          </BuyGradientDark>
        </ModalFooter>
        {shouldDisplayError && (
          <ErrorListTooltip>
            {invalidCoins && (
              <ErrorListItemTooltip>{errors.selectedCoin}</ErrorListItemTooltip>
            )}
            {invalidFunds && (
              <ErrorListItemTooltip>{errors.coinValue}</ErrorListItemTooltip>
            )}
          </ErrorListTooltip>
        )}
      </Box>
    </ModalCom>
  );
}

export default BuyWTSModal;
