import { Typography, Box, Button, Link, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as ReactLink } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { TProject } from '@typescript/models/Project.model';
import { calculate, formatNr } from '@utils/functions';
import ModalCom from '@components/modals/ModalCom';
import {
  HeaderModal,
  CloseBtn,
  CheckBoxStyled,
  StepsIndicator,
  ModalH4Text,
  ModalDetailsBox,
  ModalH6Text,
  ErrorListTooltip,
  ErrorListItemTooltip,
} from '@styles/modal/SModal';
import { BuyGradientDark } from '@styles/shared/SButtons';
import { TCreateWTBListingInitialValues } from '@typescript/TModalFormik';
import useStyles from '@hooks/useStyles';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useState } from 'react';

interface Props {
  step2Modal: boolean;
  closeStep2Modal: () => void;
  openWtbStep1Modal: () => void;
  changeSteps: (number: 1 | 2) => void;
  project: TProject;
}

function WtbStep2Modal({
  step2Modal,
  closeStep2Modal,
  openWtbStep1Modal,
  changeSteps,
  project,
}: Props) {
  const {
    values,
    touched,
    errors,
    submitForm,
    setValues,
    setTouched,
    resetForm,
  } = useFormikContext<TCreateWTBListingInitialValues>();
  const { border1pxPrimaryLight } = useStyles();
  const theme = useTheme();
  const whiteTextColor = theme.palette.text.primary;

  const invalidAcceptTerms = Boolean(errors.acceptTerms && touched.acceptTerms);
  const validAcceptTerms = Boolean(!errors.acceptTerms && touched.acceptTerms);

  const [isLoading, setIsLoading] = useState(false);

  const handlePreviousStep = () => {
    closeStep2Modal();
    openWtbStep1Modal();
    changeSteps(1);
  };

  const createWtbListing = () => {
    setIsLoading(true);
    submitForm();
  };

  const getAmountInPercentage = () => {
    let percentage = calculate(
      +values.tokenAmount,
      '%?',
      project.totalLockedTokens,
    );
    percentage = +formatNr(percentage);
    return percentage;
  };

  const handleCheckBox = () => {
    setTouched({ ...touched, acceptTerms: true });
    setValues((prev) => ({
      ...prev,
      acceptTerms: !prev.acceptTerms,
    }));
  };

  return (
    <ModalCom
      open={step2Modal}
      onClose={() => {
        closeStep2Modal();
        resetForm();
      }}
    >
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Create a WTB listing
          </Typography>
          <StepsIndicator sx={{ ml: '9px' }}>Step 2 of 2 </StepsIndicator>
          <CloseBtn
            onClick={() => {
              closeStep2Modal();
              resetForm();
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>

        <Box p="15px">
          <ModalDetailsBox>
            <Box
              p="15px 15px 14px"
              sx={{ borderBottom: border1pxPrimaryLight }}
            >
              <ModalH4Text>WTB Listing Summary</ModalH4Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  borderRight: border1pxPrimaryLight,
                  p: '15px',
                  minWidth: { xs: '110px', sm: '124px' },
                }}
              >
                <ModalH6Text>Project Name</ModalH6Text>
                <ModalH6Text>Token Amount</ModalH6Text>
                <ModalH6Text>Token Price</ModalH6Text>
                <ModalH6Text>Total Cost</ModalH6Text>
                <ModalH6Text>Expires</ModalH6Text>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: '10px',
                  p: '15px',
                }}
              >
                <Box
                  sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
                >
                  <img
                    src={project.image}
                    style={{ borderRadius: '6px' }}
                    width={20}
                    height={20}
                    alt="project"
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 400,
                      fontSize: { xs: '13px', xsm: '15px' },
                    }}
                  >
                    {project.name}
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(+values.tokenAmount)} ({getAmountInPercentage()}% of
                  project)
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(+values.tokenPrice, true)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(+values.totalCost, true)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  In {values.expireIn} days
                </Typography>
              </Box>
            </Box>
          </ModalDetailsBox>

          <Box
            sx={{
              paddingTop: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckBoxStyled
                checked={values.acceptTerms}
                onClick={handleCheckBox}
                disableRipple
                checkedIcon={<CheckRoundedIcon fontSize="small" />}
                sx={{
                  borderColor: invalidAcceptTerms
                    ? theme.palette.error.main
                    : theme.palette.text.secondary,
                }}
              />
              <ModalH4Text ml={1}>
                I have seen the disclaimer, I agree with the{' '}
                <Link
                  component={ReactLink}
                  sx={{
                    textDecorationColor: whiteTextColor,
                    color: whiteTextColor,
                    fontSize: { xs: '12px', xsm: '15px' },
                  }}
                  to="/terms"
                  aria-label="terms"
                >
                  terms.
                </Link>
                <Box component="span" color={whiteTextColor}>
                  *
                </Box>
              </ModalH4Text>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '0.5rem',
            p: '15px',
            borderTop: border1pxPrimaryLight,
          }}
        >
          <Button
            onClick={handlePreviousStep}
            variant="outlined"
            sx={{
              padding: '0 0.4rem',
              borderColor: theme.palette.text.secondary,
              color: theme.palette.text.secondary,
              ':hover': {
                backgroundColor: theme.palette.background.default,
                color: whiteTextColor,
              },
            }}
          >
            <ArrowBackIcon
              sx={{
                width: 25,
                height: 25,
                color: 'inherit',
              }}
            />
          </Button>
          <BuyGradientDark
            onClick={createWtbListing}
            disabled={!validAcceptTerms || isLoading}
          >
            Buy
          </BuyGradientDark>
        </Box>
        {invalidAcceptTerms && (
          <ErrorListTooltip>
            <ErrorListItemTooltip>{errors.acceptTerms}</ErrorListItemTooltip>
          </ErrorListTooltip>
        )}
      </>
    </ModalCom>
  );
}
export default WtbStep2Modal;
