import { Svgs } from '@utils/svgs';
import useUtils from '@hooks/useUtils';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
  Link,
  useTheme,
  Button,
} from '@mui/material';
import { useMoralis } from 'react-moralis';
import { toRem } from '@utils/functions';
import { useEffect, useState } from 'react';
import ModalCom from '@components/modals/ModalCom';
import useUser from '@hooks/useUser';
import { useAppDispatch, useAppSelector } from '@store/store';
import { hideLoginPopup } from '@store/slices/connectSlice';
import SocialConnection from './SocialConnection';

function Login() {
  const { showLoginPopup, hasSocials } = useAppSelector(
    (state) => state.connect,
  );
  const dispatch = useAppDispatch();
  const { login, incompatibleChain, currentNetworkLabel, switchCorrectChain } =
    useUtils();
  const hasSkippedNotification = Boolean(localStorage.getItem('socialSkipped'));
  const { currentUser } = useUser();
  const { enableWeb3, isWeb3Enabled } = useMoralis();
  const [hasMetamaskExtension, setHasMetamaskExtension] =
    useState<boolean>(false);
  const theme = useTheme();
  const [copiedText, setCopiedText] = useState('');

  const [copyState, setCopyState] = useState(false);

  const handleLogin = async () => {
    await login('metamask');
  };

  const handleCopyToClipboard = async () => {
    navigator.clipboard.writeText('https://app.legion.ventures');
    setCopiedText('https://app.legion.ventures');
    setCopyState(true);
  };

  const handleCorrectMessage = () => {
    if (currentUser && !hasSocials && !hasSkippedNotification) {
      return <SocialConnection />;
    }
    if (!isWeb3Enabled) {
      return (
        <>
          <Typography
            sx={{
              fontSize: { md: toRem(24), xs: toRem(20) },
            }}
            variant="h3"
          >
            Please authorize the app
          </Typography>

          <Typography mt={toRem(16)}>
            You will need to first authorize the app from metamask to be able to
            login.
          </Typography>
          <ButtonGradientDark
            sx={{ width: 'auto', marginTop: theme.spacing(4) }}
            onClick={() => enableWeb3()}
          >
            Authorize App
          </ButtonGradientDark>
        </>
      );
    }
    if (incompatibleChain) {
      return (
        <>
          <Typography
            sx={{
              fontSize: { md: toRem(24), xs: toRem(20) },
            }}
            variant="h3"
          >
            Switch to a supported chain
          </Typography>

          <Typography mt={toRem(16)}>
            You are not connected to the {currentNetworkLabel}.
          </Typography>
          <ButtonGradientDark
            sx={{ width: 'auto', marginTop: theme.spacing(4) }}
            onClick={() => switchCorrectChain()}
          >
            Switch to {currentNetworkLabel}
          </ButtonGradientDark>
        </>
      );
    }
    if (!currentUser) {
      return (
        <>
          <Typography
            sx={{
              fontSize: { md: toRem(24), xs: toRem(20) },
            }}
            variant="h3"
          >
            Welcome to Legion Ventures,
            <br /> please login to start using the app.
          </Typography>

          <Typography mt={toRem(16)}>
            By clicking "Metamask", a new window will open to establish a secure
            connection between the Metamask and Legion Ventures.
          </Typography>
          <ButtonGradientDark
            sx={{ width: 'auto', marginTop: theme.spacing(4) }}
            onClick={handleLogin}
          >
            <Svgs.Metamask height={18} width={100} />
          </ButtonGradientDark>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    if (window?.ethereum) {
      setHasMetamaskExtension(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCopyState(false);
    }, 5000);
  }, [copiedText]);

  useEffect(() => {
    if (isWeb3Enabled && currentUser && !incompatibleChain) {
      dispatch(hideLoginPopup());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, incompatibleChain, isWeb3Enabled]);

  return (
    <ModalCom onClose={() => dispatch(hideLoginPopup())} open={showLoginPopup}>
      <Box textAlign="center" padding={toRem(36)}>
        <Container maxWidth="xsm">
          {hasMetamaskExtension ? (
            <Box>
              <Box>{handleCorrectMessage()}</Box>
            </Box>
          ) : (
            <Box display="block">
              <Box>
                <Typography>
                  We couldn't find your Metamask extension. If you are on
                  desktop, please install Metamask. If you are on mobile, use
                  your Metamask app.
                </Typography>

                <Box mt={toRem(16)} textAlign="left">
                  <Accordion>
                    <AccordionSummary>
                      <Typography>I am on desktop.</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      Add the metamask extension using{' '}
                      <Link
                        target="_blank"
                        href="https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                      >
                        this link
                      </Link>{' '}
                      and follow the steps to install it.
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary>I am on mobile.</AccordionSummary>

                    <AccordionDetails>
                      <Typography>1. Open Metamask</Typography>
                      <Typography>
                        2. On the bottom navigation bar, tap on browse icon
                        (left to the settings icon)
                      </Typography>
                      <Typography>
                        3. Put https://app.legion.ventures in the url.
                      </Typography>

                      <Button
                        sx={{
                          padding: '0.7rem 0.5rem',
                          borderRadius: '0 6px 6px 0',
                          display: 'flex',
                          flexShrink: 0,
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: `1px solid ${theme.palette.primary.light}`,
                          height: '100%',
                          marginTop: toRem(8),
                        }}
                        onClick={handleCopyToClipboard}
                      >
                        {copyState ? 'Copied!' : 'Copy URL'}
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </ModalCom>
  );
}

export default Login;
