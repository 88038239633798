import { Box, Typography, useTheme } from '@mui/material';
import { TListing } from '@typescript/models/Listing.model';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonGradientDark, BuyGradientDark } from '@styles/shared/SButtons';
import { CloseBtn, HeaderModal, ModalFooter } from '@styles/modal/SModal';
import ModalCom from '@components/modals/ModalCom';
import { toRem } from '@utils/functions';
import { useState } from 'react';
import useNotification from '@hooks/useNotification';
import useListings from '@hooks/useListings';

interface Props {
  listing: TListing;
  openModal: boolean;
  closeModal: () => void;
}

function ClaimExpiredListingTokensModal({
  openModal,
  closeModal,
  listing,
}: Props) {
  const { notifyError, notifySuccess } = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const theme = useTheme();

  const { claimTokens } = useListings();

  const handleClaimTokens = async () => {
    try {
      setIsLoading(true);
      await claimTokens({ id: listing.id });
      notifySuccess('You have claimed your tokens!');
    } catch (error) {
      notifyError('Something went wrong!');
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <ModalCom open={openModal} onClose={() => closeModal()}>
      <Box>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Confirmation
          </Typography>
          <CloseBtn
            onClick={() => {
              closeModal();
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>
        <Typography
          variant="h6"
          sx={{
            fontSize: '17px',
            p: '15px',
            color: theme.palette.text.secondary,
          }}
        >
          Are you sure you want to claim this listing?
        </Typography>
        <ModalFooter
          sx={{
            display: 'flex',
            gap: '12px',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <BuyGradientDark
            onClick={handleClaimTokens}
            sx={{
              padding: {
                xs: `${toRem(18)} ${toRem(16)}`,
                sm: `${toRem(11)} ${toRem(16)}`,
              },
            }}
          >
            Claim
          </BuyGradientDark>
          <ButtonGradientDark
            variant="contained"
            onClick={() => closeModal()}
            fullWidth
            disabled={isLoading}
            sx={{
              boxShadow: 'none',
              padding: {
                xs: `${toRem(18)} ${toRem(16)}`,
                sm: `${toRem(11)} ${toRem(16)}`,
              },
            }}
          >
            Cancel
          </ButtonGradientDark>
        </ModalFooter>
      </Box>
    </ModalCom>
  );
}

export default ClaimExpiredListingTokensModal;
