import { Typography, Box, InputAdornment, Link, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { TOffer } from '@typescript/models/Offer.model';
import { useFormik } from 'formik';
import { TCoin } from '@typescript/TCoin';
import ModalCom from '@components/modals/ModalCom';
import CloseIcon from '@mui/icons-material/Close';
import useUtils from '@hooks/useUtils';
import { MakeOfferInitialValues } from '@formik/modal/ModalInitialData';
import { MakeOfferValidation } from '@formik/modal/ModalValidation';
import {
  CloseBtn,
  CurrentPriceBox,
  ErrorListItemTooltip,
  ErrorListTooltip,
  FieldStyled,
  HeaderModal,
  ModalFooter,
  ModalH6Text,
  QuestionMarkStyled,
} from '@styles/modal/SModal';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import { TMakeOfferInitialValues } from '@typescript/TModalFormik';
import useOffers from '@hooks/useOffers';
import {
  calculateProfitLoss,
  cryptoFormat,
  formatNr,
  formatModalDecimals,
  sliceWalletAddress,
  calculate,
} from '@utils/functions';
import { TListing } from '@typescript/models/Listing.model';
import { border1pxPrimaryLight } from '@styles/shared/SCss';
import useUser from '@hooks/useUser';
import { updateOfferZod } from '@typescript/dtos/offer/updateOffer.dto';
import useNotification from '@hooks/useNotification';

interface Props {
  openModal: boolean;
  closeModal: () => void;
  offer: TOffer;
}

function ModifyOfferModal({ openModal, closeModal, offer }: Props) {
  const { userCoins } = useUser();

  const { modifyOffer } = useOffers();

  const { serverNetwork } = useUtils();
  const { notifySuccess, notifyError } = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const networkLink =
    serverNetwork === 'bsc'
      ? 'https://bscscan.com/address/'
      : 'https://testnet.bscscan.com/address/';

  const handleModifyOffer = async (values: TMakeOfferInitialValues) => {
    try {
      if (values.selectedCoin !== '') {
        await modifyOffer(
          updateOfferZod.parse({
            id: offer.id,
            offerStatus: offer.status,
            offerCoin: values.selectedCoin,
            offeredPrice: +values.offeredPrice,
            offeredTokenPrice: +values.offeredTokenPrice,
          }),
        );
        notifySuccess('Offer Modified!');
      } else {
        notifyError('Offer Coin not supported!');
      }
    } catch (error) {
      notifyError('Offer Modify failed!');
      throw error;
    }
  };

  const formik = useFormik({
    initialValues: MakeOfferInitialValues,
    validationSchema: MakeOfferValidation(offer.listing.tokenPrice),
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await handleModifyOffer(values);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
      closeModal();
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setValues,
    resetForm,
    setTouched,
  } = formik;

  const invalidOfferedTokenPrice = Boolean(
    errors.offeredTokenPrice && touched.offeredTokenPrice,
  );
  const invalidOfferedPrice = Boolean(
    errors.offeredPrice && touched.offeredPrice,
  );

  const invalidCoinsValue = Boolean(errors.coinValue && touched.coinValue);

  const validOfferedTokenPrice = Boolean(
    !errors.offeredTokenPrice && touched.offeredTokenPrice,
  );
  const validOfferedPrice = Boolean(
    !errors.offeredPrice && touched.offeredPrice,
  );
  const validCoinsValue = Boolean(!errors.coinValue && touched.coinValue);

  const shouldDisplayError =
    invalidOfferedTokenPrice || invalidOfferedPrice || invalidCoinsValue;

  const isModifyOfferValid =
    validOfferedTokenPrice && validOfferedPrice && validCoinsValue;

  const handleSelectedCoin = (coin: TCoin) => {
    setValues((prev) => ({
      ...prev,
      selectedCoin: coin.name,
      coinValue: coin.balance,
    }));
  };

  const getModalAskingPrice = (currentListing: TListing) => {
    const style = {
      fontWeight: 400,
      paddingTop: { xs: '0.5rem', xsm: '1rem' },
      fontSize: { xs: '14px', xsm: '16px' },
    };

    return (
      <Tooltip title={currentListing.tokenPrice} placement="top-start">
        <Box sx={{ display: 'flex', gap: 1 }}>
          {cryptoFormat(currentListing.tokenPrice, style)}
          {calculateProfitLoss(
            currentListing.project.currentPrice,
            currentListing.tokenPrice,
            style,
          )}
        </Box>
      </Tooltip>
    );
  };

  const handleOfferPrice = (offeredTokenPrice: string) => {
    const offeredTokenPriceFormatted = formatModalDecimals(offeredTokenPrice);

    const totalOfferCost = calculate(
      offer.listing.amount,
      '*',
      +offeredTokenPriceFormatted,
    );

    const totalOfferCostFormatted = Number.isNaN(totalOfferCost)
      ? '0'
      : formatModalDecimals(totalOfferCost.toString());

    setTouched({ ...touched, offeredPrice: true, offeredTokenPrice: true });
    setValues((prev) => ({
      ...prev,
      offeredPrice: totalOfferCostFormatted,
      offeredTokenPrice: offeredTokenPriceFormatted,
    }));
  };

  useEffect(() => {
    setTouched({ ...touched, selectedCoin: true, coinValue: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userCoins) {
      const currentCoinBalance = userCoins.find(
        ({ name }) => name === offer.offerCoin,
      );

      setValues((prev) => ({
        ...prev,
        selectedCoin: offer.offerCoin,
        coinValue: currentCoinBalance?.balance ?? 0,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  return (
    <ModalCom
      open={openModal}
      onClose={() => {
        closeModal();
        resetForm();
      }}
    >
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Modify Offer
          </Typography>
          <CloseBtn
            onClick={() => {
              closeModal();
              resetForm();
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>

        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <Box>
              <ModalH6Text>Project name</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                }}
              >
                <img
                  src={offer.listing.project.image}
                  style={{ borderRadius: '6px' }}
                  width={24}
                  height={24}
                  alt="project"
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: '13px', xsm: '15px' },
                    fontWeight: 400,
                    paddingLeft: '0.5rem',
                  }}
                >
                  {offer.listing.project.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Current Price</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '10px',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    paddingRight: '0.3rem',
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {cryptoFormat(offer.listing.project.currentPrice)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <ModalH6Text>Asking Price</ModalH6Text>
              <CurrentPriceBox>
                {getModalAskingPrice(offer.listing)}
              </CurrentPriceBox>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
            borderBottom: border1pxPrimaryLight,
            borderTop: border1pxPrimaryLight,
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <ModalH6Text>Your Offered Price</ModalH6Text>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  paddingTop: '10px',
                  fontSize: { xs: '13px', sm: '15px' },
                }}
              >
                {formatNr(+offer.offeredPrice, true)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <ModalH6Text sx={{ marginBottom: '8px' }}>Listing User</ModalH6Text>
            <Link
              href={`${networkLink}${offer.listing.user.ethAddress}`}
              target="_blank"
              sx={{ fontSize: { xs: '13px', xsm: '15px' } }}
              aria-label="wallet address"
            >
              {sliceWalletAddress(offer.listing.user.ethAddress)}
            </Link>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box>
              <ModalH6Text>Token Amount</ModalH6Text>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '14px', xsm: '15px' },
                  paddingTop: '10px',
                }}
              >
                {formatNr(offer.listing.amount, undefined, 0)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
            borderBottom: border1pxPrimaryLight,
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <Box>
              <ModalH6Text>Total Cost</ModalH6Text>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '13px', xsm: '15px' },
                  paddingTop: '10px',
                }}
              >
                {formatNr(offer.listing.price, true)}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            padding: '15px',
            borderBottom: border1pxPrimaryLight,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ModalH6Text mr={1}>Listing Available Coins</ModalH6Text>
            <Tooltip
              title="You can choose one stable coin"
              placement="right-end"
            >
              <QuestionMarkStyled />
            </Tooltip>
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              paddingTop: '1rem',
            }}
          >
            {userCoins &&
              userCoins.map((coin) => (
                <Box
                  key={coin.name}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <ButtonGradientDark
                    variant={
                      values.selectedCoin === coin.name
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => handleSelectedCoin(coin)}
                    fullWidth
                  >
                    {' '}
                    {coin.name}
                  </ButtonGradientDark>
                  <Typography mt="0.1rem">{coin.balance}</Typography>
                </Box>
              ))}
          </Box>
        </Box>

        <Box
          sx={{
            padding: '18px 15px 15px',
          }}
        >
          <FieldStyled
            id="offerPrice"
            name="offerPrice"
            type="number"
            label="Your Offer"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginBottom: '2px' }}>
                  $
                </InputAdornment>
              ),
            }}
            onBlur={handleBlur}
            onChange={(event) => handleOfferPrice(event.target.value)}
            value={values.offeredTokenPrice}
            error={
              Boolean(errors.offeredTokenPrice) && touched.offeredTokenPrice
            }
            fullWidth
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: '8px',
            }}
          >
            <ModalH6Text mr={1}>Offer Total Cost:</ModalH6Text>
            <Typography variant="h6" sx={{ fontSize: '13px' }}>
              {formatNr(+values.offeredPrice, true)}
            </Typography>
          </Box>
        </Box>

        <ModalFooter>
          <ButtonGradientDark
            fullWidth
            type="submit"
            onClick={() => handleSubmit()}
            disabled={!isModifyOfferValid || isLoading}
          >
            Modify offer
          </ButtonGradientDark>
        </ModalFooter>
        {shouldDisplayError && (
          <ErrorListTooltip>
            {invalidOfferedTokenPrice && (
              <ErrorListItemTooltip>
                {errors.offeredTokenPrice}
              </ErrorListItemTooltip>
            )}

            {invalidOfferedPrice && (
              <ErrorListItemTooltip>{errors.offeredPrice}</ErrorListItemTooltip>
            )}

            {invalidCoinsValue && (
              <ErrorListItemTooltip>{errors.coinValue}</ErrorListItemTooltip>
            )}
          </ErrorListTooltip>
        )}
      </>
    </ModalCom>
  );
}

export default ModifyOfferModal;
