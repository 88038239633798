import { Typography, Box, InputAdornment, Link, Tooltip } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { TListing } from '@typescript/models/Listing.model';
import { TCoin } from '@typescript/TCoin';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import useUtils from '@hooks/useUtils';
import { MakeOfferInitialValues } from '@formik/modal/ModalInitialData';
import { MakeOfferValidation } from '@formik/modal/ModalValidation';
import ModalCom from '@components/modals/ModalCom';
import {
  CloseBtn,
  CurrentPriceBox,
  ErrorListItemTooltip,
  ErrorListTooltip,
  FieldStyled,
  HeaderModal,
  ModalFooter,
  ModalH6Text,
  QuestionMarkStyled,
} from '@styles/modal/SModal';
import { ButtonGradientDark, BuyGradientDark } from '@styles/shared/SButtons';
import {
  calculateProfitLoss,
  cryptoFormat,
  formatNr,
  formatModalDecimals,
  sliceWalletAddress,
  calculate,
  toRem,
} from '@utils/functions';
import { border1pxPrimaryLight } from '@styles/shared/SCss';
import useUser from '@hooks/useUser';
import { CreateOfferDTO } from '@typescript/dtos/offer/createOffer.dto';

interface Props {
  makeOffer: (offer: CreateOfferDTO) => Promise<void>;
  listing: TListing;
  closeModal: () => void;
  openModal: boolean;
}

function MakeOfferModal({ makeOffer, listing, closeModal, openModal }: Props) {
  const { userCoins } = useUser();

  const [listingCoinsData, setListingCoinsData] = useState<TCoin[]>([]);

  const { serverNetwork } = useUtils();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const networkLink =
    serverNetwork === 'bsc'
      ? 'https://bscscan.com/address/'
      : 'https://testnet.bscscan.com/address/';

  const formik = useFormik({
    initialValues: MakeOfferInitialValues,
    validationSchema: MakeOfferValidation(listing.tokenPrice || 0),
    onSubmit: async (values) => {
      if (values.selectedCoin !== '') {
        const offerObj = {
          offerCoin: values.selectedCoin,
          offeredPrice: +values.offeredPrice,
          offeredTokenPrice: +values.offeredTokenPrice,
          listingId: listing.id,
        };
        setIsLoading(true);
        await makeOffer(offerObj);
        setIsLoading(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setValues,
    resetForm,
    setTouched,
  } = formik;

  const invalidOfferedTokenPrice = Boolean(
    errors.offeredTokenPrice && touched.offeredTokenPrice,
  );
  const invalidOfferedPrice = Boolean(
    errors.offeredPrice && touched.offeredPrice,
  );
  const invalidSelectedCoins = Boolean(
    errors.selectedCoin && touched.selectedCoin,
  );

  const validOfferedTokenPrice = Boolean(
    !errors.offeredTokenPrice && touched.offeredTokenPrice,
  );

  const validOfferedPrice = Boolean(
    !errors.offeredPrice && touched.offeredPrice,
  );

  const validSelectedCoins = Boolean(!errors.selectedCoin);

  const shouldDisplayError =
    invalidOfferedTokenPrice || invalidSelectedCoins || invalidOfferedPrice;

  const isValidMakeOfferModal =
    validOfferedTokenPrice && validSelectedCoins && validOfferedPrice;

  const handleClose = () => {
    closeModal();
    resetForm();
  };

  const setListingAvailableCoins = useCallback(() => {
    const listingCoinData: TCoin[] = [];
    listing.coins.forEach((listingCoin) => {
      if (userCoins) {
        const foundedCoin = userCoins.find(
          (userCoin) => userCoin.name === listingCoin,
        );
        if (foundedCoin) {
          listingCoinData.push(foundedCoin);
        }
      }
    });
    setListingCoinsData(listingCoinData);
  }, [listing.coins, userCoins]);

  const handleSelectedCoin = (coin: TCoin) => {
    setTouched({ ...touched, selectedCoin: true, coinValue: true });

    setValues((prev) => ({
      ...prev,
      selectedCoin: coin.name,
      coinValue: coin.balance,
    }));
  };

  const handleOfferPrice = (offeredTokenPrice: string) => {
    const offeredTokenPriceFormatted = formatModalDecimals(offeredTokenPrice);

    const totalOfferCost = calculate(
      listing.amount,
      '*',
      +offeredTokenPriceFormatted,
    );

    const totalOfferCostFormatted = Number.isNaN(totalOfferCost)
      ? '0'
      : formatModalDecimals(totalOfferCost.toString());

    setTouched({ ...touched, offeredPrice: true, offeredTokenPrice: true });
    setValues((prev) => ({
      ...prev,
      offeredPrice: totalOfferCostFormatted,
      offeredTokenPrice: offeredTokenPriceFormatted,
    }));
  };

  const getModalAskingPrice = (currentListing: TListing) => {
    const style = {
      fontWeight: 400,
      paddingTop: { xs: '0.5rem', xsm: '1rem' },
      fontSize: { xs: '13px', xsm: '15px' },
    };

    return (
      <Tooltip title={currentListing.tokenPrice} placement="right-end">
        <Box sx={{ display: 'flex', gap: 1 }}>
          {cryptoFormat(currentListing.tokenPrice, style)}
          {calculateProfitLoss(
            currentListing.project.currentPrice,
            currentListing.tokenPrice,
            style,
          )}
        </Box>
      </Tooltip>
    );
  };

  useEffect(() => {
    setListingAvailableCoins();
  }, [setListingAvailableCoins]);

  return (
    <ModalCom open={openModal} onClose={handleClose}>
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Make an Offer
          </Typography>
          <CloseBtn onClick={handleClose}>
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>
        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <Box>
              <ModalH6Text>Project name</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                }}
              >
                <img
                  src={listing.project.image}
                  style={{ borderRadius: '6px' }}
                  width={24}
                  height={24}
                  alt="project"
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: '13px', xsm: '15px' },
                    fontWeight: 400,
                    paddingLeft: '0.5rem',
                  }}
                >
                  {listing.project.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Current Price</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    paddingRight: '0.3rem',
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  <CurrentPriceBox>
                    {cryptoFormat(listing.project.currentPrice)}
                  </CurrentPriceBox>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <ModalH6Text>Asking Price</ModalH6Text>
              <CurrentPriceBox>{getModalAskingPrice(listing)}</CurrentPriceBox>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
            borderBottom: border1pxPrimaryLight,
            borderTop: border1pxPrimaryLight,
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <ModalH6Text sx={{ marginBottom: '8px' }}>Listing User</ModalH6Text>
            <Link
              href={`${networkLink}${listing.user.ethAddress}`}
              target="_blank"
              sx={{ fontSize: { xs: '13px', xsm: '15px' } }}
              aria-label="wallet address"
            >
              {sliceWalletAddress(listing.user.ethAddress)}
            </Link>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Token Amount</ModalH6Text>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '14px', xsm: '15px' },
                  paddingTop: '10px',
                }}
              >
                {formatNr(listing.amount)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box>
              <ModalH6Text>Total Cost</ModalH6Text>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '13px', xsm: '15px' },
                  paddingTop: '10px',
                }}
              >
                {formatNr(listing.price, true)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: '15px', borderBottom: border1pxPrimaryLight }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ModalH6Text mr={1}>Pay in</ModalH6Text>
            <Tooltip
              title="You can choose one stable coin"
              placement="right-end"
            >
              <QuestionMarkStyled />
            </Tooltip>
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '0.7rem',
              paddingTop: '11px',
            }}
          >
            {listingCoinsData.map((coin) => (
              <Box
                key={coin.name}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ButtonGradientDark
                  variant={
                    values.selectedCoin === coin.name ? 'contained' : 'outlined'
                  }
                  onClick={() => handleSelectedCoin(coin)}
                  fullWidth
                  disableRipple
                >
                  {coin.name}
                </ButtonGradientDark>

                <Typography variant="h6" mt="8px">
                  {formatNr(coin.balance, true)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={{ p: '17px 15px 0px' }}>
          <FieldStyled
            id="offeredTokenPrice"
            name="offeredTokenPrice"
            type="number"
            label="Your Offer"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onBlur={handleBlur}
            onChange={(event) => handleOfferPrice(event.target.value)}
            value={values.offeredTokenPrice}
            error={
              Boolean(errors.offeredTokenPrice) && touched.offeredTokenPrice
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            placeholder="0"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '8px 15px 15px',
          }}
        >
          <ModalH6Text>Offer Total Cost:</ModalH6Text>
          <Typography variant="h6" sx={{ fontSize: '13px', ml: '6px' }}>
            {formatNr(+values.offeredPrice, true)}
          </Typography>
        </Box>
        <ModalFooter>
          <BuyGradientDark
            onClick={() => handleSubmit()}
            sx={{
              padding: {
                xs: `${toRem(18)} ${toRem(16)}`,
                sm: `${toRem(11)} ${toRem(16)}`,
              },
            }}
            disabled={!isValidMakeOfferModal || isLoading}
          >
            Make Offer
          </BuyGradientDark>
        </ModalFooter>
        {shouldDisplayError && (
          <ErrorListTooltip>
            {invalidOfferedTokenPrice && (
              <ErrorListItemTooltip>
                {errors.offeredTokenPrice}
              </ErrorListItemTooltip>
            )}
            {invalidSelectedCoins && (
              <ErrorListItemTooltip>{errors.selectedCoin}</ErrorListItemTooltip>
            )}
            {invalidOfferedPrice && (
              <ErrorListItemTooltip>{errors.offeredPrice}</ErrorListItemTooltip>
            )}
          </ErrorListTooltip>
        )}
      </>
    </ModalCom>
  );
}

export default MakeOfferModal;
