import { Modal, ModalProps, SxProps } from '@mui/material';
import { ModalContainer } from '@styles/modal/SModal';

interface ModalComProps extends ModalProps {
  containerSx?: SxProps;
}

function ModalCom({ children, containerSx, ...props }: ModalComProps) {
  return (
    <Modal {...props}>
      <ModalContainer sx={containerSx}>{children}</ModalContainer>
    </Modal>
  );
}

export default ModalCom;
