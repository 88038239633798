import { Typography, Box } from '@mui/material';
import ModalCom from '@components/modals/ModalCom';

import { ButtonGradientDark } from '@styles/shared/SButtons';
import { Svgs } from '@utils/svgs';

interface Props {
  open: boolean;
  onClose: () => void;
  error?: string;
}

function TradeTxFailedModal({ open, onClose, error }: Props) {
  const defaultError = 'Something went wrong..';

  return (
    <ModalCom open={open} onClose={onClose}>
      <Box
        sx={{
          p: '36px ',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ maxWidth: '76px', width: '100%' }}>
          <Svgs.Error style={{ marginBottom: '24px' }} />
        </Box>
        <Typography variant="h4" sx={{ mb: '14px' }}>
          {error || defaultError}
        </Typography>
        <ButtonGradientDark fullWidth onClick={onClose}>
          Try again
        </ButtonGradientDark>
      </Box>
    </ModalCom>
  );
}
export default TradeTxFailedModal;
