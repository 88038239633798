import { ChainId } from '@constants/CChains';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { useGetServerNetworkQuery } from '@store/api/utilApi';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import { ServerNetwork, toRem } from '@utils/functions';
import { useChain } from 'react-moralis';
import ModalCom from './ModalCom';

interface Props {
  onClose: () => void;
  open: boolean;
  chainSwitched: () => void;
}

export default function SwitchToEthNetworkModal({
  onClose,
  open,
  chainSwitched,
}: Props) {
  const { data: serverNetwork } = useGetServerNetworkQuery({});

  const theme = useTheme();

  const { chainId, switchNetwork } = useChain();

  const ethNetwork =
    serverNetwork === ServerNetwork.BSC ? 'Ethereum' : 'Sepolia';

  const switchToEthChain = async () => {
    if (serverNetwork === ServerNetwork.BSC && chainId !== ChainId.ETH) {
      await switchNetwork(ChainId.ETH);
      chainSwitched();
    }
    if (
      serverNetwork === ServerNetwork.BSC_TESTNET &&
      chainId !== ChainId.SEPOLIA
    ) {
      await switchNetwork(ChainId.SEPOLIA);
      chainSwitched();
    }
  };

  return (
    <ModalCom onClose={onClose} open={open}>
      <Box textAlign="center" padding={toRem(36)}>
        <Container maxWidth="xsm">
          <Typography
            sx={{
              fontSize: { md: toRem(24), xs: toRem(20) },
            }}
            variant="h3"
          >
            Switch to Ethereum chain
          </Typography>

          <Typography mt={toRem(16)}>
            You are not connected to the {ethNetwork}.
          </Typography>
          <ButtonGradientDark
            sx={{ width: 'auto', marginTop: theme.spacing(4) }}
            onClick={switchToEthChain}
          >
            Switch to {ethNetwork}
          </ButtonGradientDark>
        </Container>
      </Box>
    </ModalCom>
  );
}
