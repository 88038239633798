import {
  Box,
  Typography,
  useTheme,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { TCoin } from '@typescript/TCoin';
import CloseIcon from '@mui/icons-material/Close';
import { useFormikContext } from 'formik';
import { TCreateWTIListingInitialValues } from '@typescript/TModalFormik';
import {
  CloseBtn,
  FieldStyled,
  HeaderModal,
  QuestionMarkStyled,
  StepsIndicator,
  ModalH6Text,
  CurrentPriceBox,
  ModalFooter,
  ErrorListTooltip,
  ErrorListItemTooltip,
} from '@styles/modal/SModal';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import ModalCom from '@components/modals/ModalCom';
import { Coin } from '@constants/CCoin';
import {
  cryptoFormat,
  formatNr,
  formatModalDecimals,
  calculate,
  calculateTotalValueAfterFee,
} from '@utils/functions';
import useStyles from '@hooks/useStyles';
import { useEffect } from 'react';
import { TProject } from '@typescript/models/Project.model';

interface Props {
  step1Modal: boolean;
  closeStep1Modal: () => void;
  changeModalSteps: (number: 1 | 2) => void;
  openWtbStep2Modal: () => void;
  project: TProject;
  userCoins?: TCoin[];
  feeInPercentage: number;
}

function WtiStep1Modal({
  step1Modal,
  closeStep1Modal,
  changeModalSteps,
  openWtbStep2Modal,
  project,
  userCoins,
  feeInPercentage = 0,
}: Props) {
  const theme = useTheme();

  const {
    values,
    handleBlur,
    touched,
    errors,
    setValues,
    setTouched,
    resetForm,
  } = useFormikContext<TCreateWTIListingInitialValues>();

  const invalidTotalCost = Boolean(errors.totalCost && touched.totalCost);
  const validTotalCost = Boolean(!errors.totalCost && touched.totalCost);

  const invalidCoins = Boolean(errors.coin && touched.coin);

  const validCoins = Boolean(!errors.coin && touched.coin);

  const shouldDisplayError = invalidTotalCost || invalidCoins;

  const isWTIStep1Valid = validCoins && validTotalCost;

  const { border1pxPrimaryLight } = useStyles();

  const handleChangeTotalCost = (totalCost: string) => {
    setTouched({ ...touched, totalCost: true, tokenAmount: true });

    if (totalCost === '') {
      setValues((prev) => ({
        ...prev,
        totalCost: '',
        tokenAmount: '',
      }));
      return;
    }

    const totalCostFormatted = formatModalDecimals(totalCost);

    const newTokenAmount = calculate(
      +totalCostFormatted,
      '/',
      project.tokenPrice,
    );
    const tokenAmountAfterFee = calculateTotalValueAfterFee(
      feeInPercentage,
      newTokenAmount,
    );

    const newTokenAmountFormatted = formatModalDecimals(
      tokenAmountAfterFee.toString(),
    );

    setValues((prev) => ({
      ...prev,
      tokenAmount: newTokenAmountFormatted,
      totalCost: totalCostFormatted,
    }));
  };

  const handleClickCoinButton = (currentCoin: Coin) => {
    setValues((prev) => ({ ...prev, coin: currentCoin }));
  };

  const handleNextStep = () => {
    if (isWTIStep1Valid) {
      closeStep1Modal();
      openWtbStep2Modal();
      changeModalSteps(2);
    } else {
      setTouched({
        totalCost: true,
        coin: true,
      });
    }
  };

  useEffect(() => {
    setTouched({ ...touched, coin: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalCom
      open={step1Modal}
      onClose={() => {
        closeStep1Modal();
        resetForm();
      }}
    >
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            New Investment
          </Typography>
          <StepsIndicator>Step 1 of 2 </StepsIndicator>
          <CloseBtn
            onClick={() => {
              closeStep1Modal();
              resetForm();
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>
        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <Box>
              <ModalH6Text>Project Name</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                }}
              >
                <img
                  src={project.image}
                  style={{ borderRadius: '6px' }}
                  width={24}
                  height={24}
                  alt="project"
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: '13px', xsm: '15px' },
                    fontWeight: 400,
                    paddingLeft: '0.5rem',
                  }}
                >
                  {project.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Currently Raised</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '10px',
                }}
              >
                <Tooltip
                  title={`${project.investmentAmount} / ${project.fundraisingTarget}`}
                  placement="right-end"
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 400,
                      paddingRight: '0.3rem',
                      fontSize: { xs: '13px', xsm: '15px' },
                    }}
                  >
                    ${formatNr(project.investmentAmount, undefined, 0)}/
                    {formatNr(project.fundraisingTarget, undefined, 0)}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <ModalH6Text>Token Price</ModalH6Text>
              <Tooltip title={project.currentPrice} placement="right-end">
                <CurrentPriceBox>
                  {cryptoFormat(project.currentPrice)}
                </CurrentPriceBox>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: border1pxPrimaryLight,
            display: 'flex',
            padding: '15px',
            gap: '15px',
          }}
        >
          <Box sx={{ display: 'flex', gap: '4px' }}>
            <ModalH6Text>Min</ModalH6Text>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 400,
                fontSize: { xs: '13px', xsm: '15px' },
              }}
            >
              {cryptoFormat(project.minInvestment, { fontSize: '13px' })}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '4px' }}>
            <ModalH6Text>Max</ModalH6Text>

            <Typography
              variant="h4"
              sx={{
                fontWeight: 400,
                fontSize: { xs: '13px', xsm: '15px' },
              }}
            >
              {cryptoFormat(project.maxInvestment, { fontSize: '13px' })}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '4px' }}>
            <ModalH6Text>Fee</ModalH6Text>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 400,
                fontSize: { xs: '13px', xsm: '15px' },
              }}
            >
              {feeInPercentage}%
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            paddingLeft: '15px',
            paddingRight: '15px',
            borderTop: border1pxPrimaryLight,
          }}
        >
          <Box
            sx={{
              paddingTop: { xs: '1rem', xsm: '18px' },
              display: 'flex',
              gap: '12px',
            }}
          >
            <FieldStyled
              id="totalCost"
              name="totalCost"
              label="Investment"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      marginBottom: '2px',
                      marginLeft: '0px',
                      '& .MuiTypography-root': { fontSize: '15px' },
                    }}
                  >
                    $
                  </InputAdornment>
                ),
              }}
              type="number"
              sx={{ flex: 1 }}
              value={values.totalCost}
              onBlur={handleBlur}
              onChange={(event) => handleChangeTotalCost(event.target.value)}
              error={invalidTotalCost}
              placeholder="0"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '8px 15px 13px 15px',
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
          }}
        >
          <ModalH6Text mr={1}>Token Amount</ModalH6Text>
          <Tooltip title={values.tokenAmount} placement="right-end">
            <Box
              sx={{
                fontSize: '13px',
              }}
            >
              {formatNr(+values.tokenAmount, false, 2)} (after fee)
            </Box>
          </Tooltip>
        </Box>
        <Box sx={{ p: '15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ModalH6Text mr={1}>Pay in</ModalH6Text>
            <Tooltip
              title="You can choose only one stable coin"
              placement="right-end"
            >
              <QuestionMarkStyled />
            </Tooltip>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '0.7rem',
              paddingTop: '1rem',
            }}
          >
            {userCoins &&
              userCoins.map((currentCoin) => (
                <Box
                  key={currentCoin.name}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <ButtonGradientDark
                    variant={
                      values.coin.includes(currentCoin.name)
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => handleClickCoinButton(currentCoin.name)}
                  >
                    {currentCoin.name}
                  </ButtonGradientDark>
                  <Typography variant="h6" mt="8px">
                    {formatNr(currentCoin.balance, true)}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>
        <ModalFooter>
          <ButtonGradientDark
            fullWidth
            onClick={handleNextStep}
            disabled={!isWTIStep1Valid}
          >
            Next step
          </ButtonGradientDark>
        </ModalFooter>
        {shouldDisplayError && (
          <ErrorListTooltip>
            {invalidTotalCost && (
              <ErrorListItemTooltip>{errors.totalCost}</ErrorListItemTooltip>
            )}
            {errors.coin && (
              <ErrorListItemTooltip>{errors.coin}</ErrorListItemTooltip>
            )}
          </ErrorListTooltip>
        )}
      </>
    </ModalCom>
  );
}
export default WtiStep1Modal;
