import {
  Box,
  Typography,
  InputAdornment,
  Tooltip,
  useTheme,
  IconButton,
} from '@mui/material';
import { TListing } from '@typescript/models/Listing.model';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { ModifyWTBListingInitialValues } from '@formik/modal/ModalInitialData';
import { WTBListingValidationSchema } from '@formik/modal/ModalValidation';
import {
  CloseBtn,
  FieldStyled,
  QuestionMarkStyled,
  HeaderModal,
  ModalH6Text,
  CurrentPriceBox,
  ExpireBox,
  ExpireInDaysButton,
  ExpireInText,
  ModalFooter,
  ErrorListTooltip,
  ErrorListItemTooltip,
  ModalH4Text,
} from '@styles/modal/SModal';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import { TCreateWTBListingInitialValues } from '@typescript/TModalFormik';
import ModalCom from '@components/modals/ModalCom';
import { Coin } from '@constants/CCoin';
import useListings from '@hooks/useListings';
import {
  calculate,
  cryptoFormat,
  formatNr,
  formatModalDecimals,
} from '@utils/functions';
import useStyles from '@hooks/useStyles';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import useUser from '@hooks/useUser';
import { Svgs } from '@utils/svgs';
import { UpdateListingDTO } from '@typescript/dtos/listing/updateListing.dto';
import { UserCoin } from '@typescript/models/User.model';
import useNotification from '@hooks/useNotification';

interface Props {
  listing: TListing;
  userCoins?: UserCoin[];
  openModal: boolean;
  closeModal: () => void;
  updateWTB: (payload: UpdateListingDTO) => Promise<void>;
}
function ModifyWTBListingModal({
  openModal,
  closeModal,
  listing,
  updateWTB,
  userCoins,
}: Props) {
  const { userInformation } = useUser();

  const { notifyError, notifySuccess } = useNotification();

  const { hightestUserBalance } = useUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { border1pxSecondaryLight } = useStyles();

  const { MAX_EXPIRE_IN_DAYS } = useListings();

  const userHightestBalance = hightestUserBalance();

  const { projectMaxLockedTokens } = listing.project;

  const expireInDaysArray = Array.from(
    { length: MAX_EXPIRE_IN_DAYS },
    (_, index) => index + 1,
  );

  const modifyWTBListing = async (values: TCreateWTBListingInitialValues) => {
    try {
      const payload = {
        id: listing.id,
        amount: +values.tokenAmount,
        coins: values.coins,
        expireIn: values.expireIn,
        price: +values.totalCost,
        negotiable: listing.negotiable,
        tokenPrice: +values.tokenPrice,
        discordName: values.discordName,
        telegramLink: values.telegramLink,
      };

      await updateWTB(payload);
      notifySuccess('WTB Listing Updated!');
    } catch (error) {
      notifyError('Modify WTB listing action failed!');
      throw error;
    } finally {
      closeModal();
    }
  };

  const formik = useFormik({
    initialValues: ModifyWTBListingInitialValues(listing),
    validationSchema: WTBListingValidationSchema(
      projectMaxLockedTokens,
      userHightestBalance,
    ),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await modifyWTBListing(values);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const {
    values,
    handleBlur,
    errors,
    touched,
    submitForm,
    setValues,
    resetForm,
    setTouched,
  } = formik;

  const invalidTokenAmount = Boolean(errors.tokenAmount && touched.tokenAmount);
  const invalidTokenPrice = Boolean(errors.tokenPrice && touched.tokenPrice);
  const invalidTotalCost = Boolean(errors.totalCost && touched.totalCost);
  const invalidCoins = Boolean(errors.coins && touched.coins);

  const validTokenAmount = Boolean(!errors.tokenAmount);
  const validTokenPrice = Boolean(!errors.tokenPrice);
  const validTotalCost = Boolean(!errors.totalCost);
  const validCoins = Boolean(!errors.coins);

  const shouldDisplayError =
    invalidTokenAmount || invalidTokenPrice || invalidTotalCost || invalidCoins;

  const isModifyWTBValid =
    validTokenAmount && validTokenPrice && validTotalCost && validCoins;

  const theme = useTheme();

  const handleClickCoinButton = (currentCoin: Coin) => {
    const newCoins = [...values.coins];
    const coinIndex = values.coins.indexOf(currentCoin);

    if (coinIndex > -1) {
      newCoins.splice(coinIndex, 1);
    } else {
      newCoins.push(currentCoin);
    }

    setValues((prev) => ({ ...prev, coins: newCoins }));
  };

  const handleChangeTokenPrice = (newTokenPrice: string) => {
    setTouched({ ...touched, totalCost: true, tokenPrice: true });

    if (newTokenPrice === '') {
      setValues((prev) => ({
        ...prev,
        totalCost: '',
        tokenPrice: '',
      }));
      return;
    }

    const newTokenPriceFormatted = formatModalDecimals(
      newTokenPrice.toString(),
    );

    if (values.tokenAmount) {
      const newTotalCost = calculate(
        +newTokenPriceFormatted,
        '*',
        +values.tokenAmount,
      );

      const newTotalCostFormatted = formatModalDecimals(
        newTotalCost.toString(),
      );

      setValues((prev) => ({
        ...prev,
        totalCost: newTotalCostFormatted,
        tokenPrice: newTokenPriceFormatted,
      }));

      return;
    }

    if (values.totalCost) {
      const newTokenAmount = calculate(
        +values.totalCost,
        '/',
        +values.tokenPrice,
      );
      const newTokenAmountFormatted = formatModalDecimals(
        newTokenAmount.toString(),
      );

      setTouched({ tokenAmount: true });
      setValues((prev) => ({
        ...prev,
        tokenAmount: newTokenAmountFormatted,
        tokenPrice: formatModalDecimals(newTokenPrice),
      }));
      return;
    }

    setValues((prev) => ({
      ...prev,
      tokenPrice: newTokenPriceFormatted,
    }));
  };

  const handleChangeTokenAmount = (newAmount: string) => {
    setTouched({ ...touched, totalCost: true, tokenAmount: true });

    if (newAmount === '') {
      setValues((prev) => ({
        ...prev,
        tokenAmount: '',
        totalCost: '',
      }));
      return;
    }

    const newAmountFormatted = formatModalDecimals(newAmount);

    if (values.tokenPrice === '') {
      setValues((prev) => ({
        ...prev,
        tokenAmount: newAmountFormatted,
      }));
      return;
    }

    const newTotalCost = calculate(
      +values.tokenPrice,
      '*',
      +newAmountFormatted,
    );
    const newTotalCostFormatted = formatModalDecimals(newTotalCost.toString());

    setValues((prev) => ({
      ...prev,
      tokenAmount: newAmountFormatted,
      totalCost: newTotalCostFormatted,
    }));
  };

  const handleChangeTotalCost = (newTotalCost: string) => {
    setTouched({ ...touched, tokenAmount: true, totalCost: true });

    if (newTotalCost === '') {
      setValues((prev) => ({
        ...prev,
        totalCost: '',
        tokenAmount: '',
      }));
      return;
    }

    const newTotalCostFormatted = formatModalDecimals(newTotalCost);

    if (values.tokenPrice === '') {
      setValues((prev) => ({
        ...prev,
        totalCost: newTotalCostFormatted,
      }));
      return;
    }

    const newTokenAmount = calculate(
      +newTotalCostFormatted,
      '/',
      +values.tokenPrice,
    );

    const newTokenAmountFormatted = formatModalDecimals(
      newTokenAmount.toString(),
    );

    setValues((prev) => ({
      ...prev,
      totalCost: newTotalCostFormatted,
      tokenAmount: newTokenAmountFormatted,
    }));
  };

  const increaseExpireDays = () => {
    setValues((prev) => ({ ...prev, expireIn: values.expireIn + 1 }));
  };

  const decreaseExpireDays = () => {
    setValues((prev) => ({ ...prev, expireIn: values.expireIn - 1 }));
  };

  const handleTelegramLink = (isAddingTelegram: boolean) => {
    if (!userInformation) {
      throw Error('No user Information found');
    }
    setTouched({ ...touched, telegramLink: true });
    if (isAddingTelegram) {
      setValues((prev) => ({
        ...prev,
        telegramLink: userInformation.telegramLink,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        telegramLink: '',
      }));
    }
  };

  const handleDiscord = (isAddingDiscord: boolean) => {
    if (!userInformation) {
      throw Error('No user Information found');
    }
    setTouched({ ...touched, discordName: true });
    if (isAddingDiscord) {
      setValues((prev) => ({
        ...prev,
        discordName: userInformation.discordName,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        discordName: '',
      }));
    }
  };

  const telegramTooltip = () => {
    if (!userInformation) {
      throw Error('No user Information found!');
    }

    if (values.telegramLink) {
      return 'Remove Telegram';
    }
    if (userInformation?.telegramLink) {
      return 'Add Telegram';
    }
    return "You don't have a Telegram link set";
  };

  const discordTooltip = () => {
    if (!userInformation) {
      throw Error('No user Information found!');
    }

    if (values.discordName) {
      return 'Remove Discord';
    }

    if (userInformation?.discordName) {
      return 'Add Discord';
    }

    return "You don't have a Discord username set";
  };

  useEffect(() => {
    setTouched({ ...touched, coins: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    <IsLoadingCom />;
  }

  return (
    <ModalCom
      open={openModal}
      onClose={() => {
        closeModal();
        resetForm();
      }}
    >
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Modify WTB listing
          </Typography>
          <CloseBtn onClick={closeModal}>
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>

        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              flex: { xs: 'none', xsm: 2.2 },
            }}
          >
            <Box>
              <ModalH6Text>Project Name</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                }}
              >
                <img
                  src={listing.project.image}
                  style={{ borderRadius: '6px' }}
                  width={24}
                  height={24}
                  alt="project"
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    paddingLeft: '0.5rem',
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {listing.project.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Balance</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '10px',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    paddingRight: '0.3rem',
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(projectMaxLockedTokens, undefined, 0)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <ModalH6Text>Current Price</ModalH6Text>
              <Tooltip
                title={listing.project.currentPrice}
                placement="right-end"
              >
                <CurrentPriceBox>
                  {cryptoFormat(listing.project.currentPrice)}
                </CurrentPriceBox>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            paddingLeft: '15px',
            paddingRight: '15px',
            borderTop: border1pxSecondaryLight,
          }}
        >
          <Box
            sx={{
              paddingTop: { xs: '1rem', xsm: '18px' },
              display: 'flex',
              gap: '12px',
            }}
          >
            <FieldStyled
              id="tokenPrice"
              name="tokenPrice"
              label="Token Price"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              sx={{ flex: 1 }}
              type="number"
              value={values.tokenPrice}
              onBlur={handleBlur}
              onChange={(event) => handleChangeTokenPrice(event.target.value)}
              error={Boolean(errors.tokenPrice) && touched.tokenPrice}
              InputLabelProps={{ shrink: true }}
            />
            <FieldStyled
              id="tokenAmount"
              name="tokenAmount"
              label="Token Amount"
              InputProps={{ sx: { pl: 0 } }}
              type="number"
              sx={{ flex: 1 }}
              value={values.tokenAmount}
              onBlur={handleBlur}
              onChange={(event) => handleChangeTokenAmount(event.target.value)}
              error={Boolean(errors.tokenAmount) && touched.tokenAmount}
              placeholder="0"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <FieldStyled
            sx={{ marginTop: '12px' }}
            id="totalCost"
            name="totalCost"
            type="number"
            label="Total Cost"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginBottom: '2  px' }}>
                  $
                </InputAdornment>
              ),
            }}
            onBlur={handleBlur}
            onChange={(event) => handleChangeTotalCost(event.target.value)}
            value={values.totalCost}
            error={Boolean(errors.totalCost) && touched.totalCost}
            fullWidth
            InputLabelProps={{ shrink: true }}
            placeholder="0"
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '8px 15px 13px 15px',
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
          }}
        >
          <ModalH6Text mr={1}>Original token price</ModalH6Text>
          <Tooltip title={listing.project.tokenPrice} placement="right-end">
            <Box
              sx={{
                fontSize: '13px',
              }}
            >
              {formatNr(+listing.project.tokenPrice, undefined, 0)}
            </Box>
          </Tooltip>
        </Box>

        <Box sx={{ p: '15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ModalH6Text mr={1}>Pay in</ModalH6Text>
            <Tooltip
              title="You can choose multiple stable coins"
              placement="right-end"
            >
              <QuestionMarkStyled />
            </Tooltip>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '0.7rem',
              paddingTop: '1rem',
            }}
          >
            {userCoins &&
              userCoins.map((currentCoin) => (
                <Box
                  key={currentCoin.name}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <ButtonGradientDark
                    variant={
                      values.coins.includes(currentCoin.name)
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => handleClickCoinButton(currentCoin.name)}
                  >
                    {currentCoin.name}
                  </ButtonGradientDark>

                  <Typography variant="h6" mt="0.1rem">
                    {formatNr(currentCoin.balance, true)}
                  </Typography>
                </Box>
              ))}
          </Box>

          <Box
            sx={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}
          >
            <ModalH6Text mr={1}>
              {`Expire In (max. ${expireInDaysArray.length} days`}
            </ModalH6Text>
            <Tooltip
              title="The period after your listing will expire and will be archived"
              placement="right-end"
            >
              <QuestionMarkStyled />
            </Tooltip>
          </Box>

          <ExpireBox>
            <ExpireInDaysButton
              disableRipple
              onClick={decreaseExpireDays}
              disabled={values.expireIn <= 1}
            >
              <RemoveRoundedIcon />
            </ExpireInDaysButton>
            <ExpireInText>{values.expireIn}</ExpireInText>
            <ExpireInDaysButton
              disableRipple
              onClick={increaseExpireDays}
              disabled={values.expireIn >= expireInDaysArray.length}
            >
              <AddRoundedIcon />
            </ExpireInDaysButton>
          </ExpireBox>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              mt: '16px',
            }}
          >
            <ModalH4Text ml={1}>Display Social:</ModalH4Text>
            <Tooltip title={`${telegramTooltip()}`}>
              <IconButton
                sx={{
                  color: values.telegramLink ? 'text.primary' : 'primary.dark',
                  '&:hover': {
                    color: 'text.primary',
                  },
                }}
                onClick={() => {
                  handleTelegramLink(!values.telegramLink);
                }}
              >
                <Svgs.TelegramIcon
                  color="inherit"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={`${discordTooltip()}`}>
              <IconButton
                sx={{
                  color: values.discordName ? 'text.primary' : 'primary.dark',
                  '&:hover': {
                    color: 'text.primary',
                  },
                }}
                onClick={() => {
                  handleDiscord(!values.discordName);
                }}
              >
                <Svgs.DiscordIcon
                  color="inherit"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <ModalFooter>
          <ButtonGradientDark
            fullWidth
            onClick={submitForm}
            disabled={!isModifyWTBValid || isLoading}
          >
            Modify Listing
          </ButtonGradientDark>
        </ModalFooter>
        {shouldDisplayError && (
          <ErrorListTooltip>
            {invalidTokenPrice && (
              <ErrorListItemTooltip>{errors.tokenPrice}</ErrorListItemTooltip>
            )}

            {invalidTokenAmount && (
              <ErrorListItemTooltip>{errors.tokenAmount}</ErrorListItemTooltip>
            )}

            {invalidTotalCost && (
              <ErrorListItemTooltip>{errors.totalCost}</ErrorListItemTooltip>
            )}
            {invalidCoins && (
              <ErrorListItemTooltip>{errors.coins}</ErrorListItemTooltip>
            )}
          </ErrorListTooltip>
        )}
      </>
    </ModalCom>
  );
}

export default ModifyWTBListingModal;
