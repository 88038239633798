import { Box, Button, Typography } from '@mui/material';
import { FieldStyled } from '@styles/modal/SModal';
import useUser from '@hooks/useUser';
import * as yup from 'yup';
import { MetamaskError } from '@utils/errors';
import useNotification from '@hooks/useNotification';
import { useFormik } from 'formik';
import { toRem } from '@utils/functions';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import VerifiedIcon from '@mui/icons-material/Verified';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { hideLoginPopup, setSocialStep } from '@store/slices/connectSlice';
import { useAppDispatch, store } from '@store/store';
import { SOCIAL_STEPS } from '@typescript/MultistepSocial.enum';

interface UserInfoInitialValues {
  discordName: string;
  telegramLink: string;
}

function SocialConnection() {
  const { userInformation, updateSocial } = useUser();

  const dispatch = useAppDispatch();

  const { notifySuccess, notifyError } = useNotification();

  const initialValues: UserInfoInitialValues = {
    discordName: userInformation?.discordName || '',
    telegramLink: userInformation?.telegramLink || '',
  };

  const validationSchema = () =>
    yup.object().shape({
      discordName: yup.string().required(),
      telegramLink: yup
        .string()
        .matches(/^https:\/\/t\.me\/\S+$/, 'Please add a valid Telegram link')
        .required(),
    });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
  });

  const { values, handleChange, handleBlur, errors, touched, setValues } =
    formik;

  const socialButtonDisabled =
    Boolean(errors.telegramLink || values.telegramLink === ' ') ||
    Boolean(errors.discordName || values.discordName === ' ');

  const handleUserUpdate = async () => {
    try {
      if (!userInformation) {
        throw Error('No user information found');
      }

      await updateSocial(
        values.discordName,
        values.telegramLink,
        userInformation,
      );
      notifySuccess('Social changed successfully!');
      setValues((prev) => ({
        ...prev,
        discordName: userInformation?.discordName || '',
      }));
    } catch (e) {
      const { message, code } = e as MetamaskError;
      if (code === 202) {
        notifyError(message);
      }
      throw e;
    }
  };

  switch (store.getState().connect.socialStep) {
    case SOCIAL_STEPS.FIRST_STEP: {
      return (
        <Box>
          <Typography
            sx={{
              fontSize: { md: toRem(24), xs: toRem(20) },
              mb: toRem(16),
            }}
            variant="h3"
          >
            Update Social Details
          </Typography>

          <Typography mb={toRem(32)}>
            Your social information is out of date. Updating your social details
            will help you connect better.
          </Typography>

          <FieldStyled
            fullWidth
            id="discordName"
            name="discordName"
            label="Discord username:"
            InputProps={{
              sx: {
                paddingLeft: 0,
                borderRadius: '4px 0 0 4px',
              },
            }}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.discordName}
            sx={{ flex: 1, minHeight: '90px' }}
            helperText={touched.discordName ? errors.discordName : ''}
            error={Boolean(errors.discordName) && touched.discordName}
          />

          <FieldStyled
            fullWidth
            id="telegramLink"
            name="telegramLink"
            label="Telegram Link"
            InputProps={{
              sx: {
                paddingLeft: 0,
                borderRadius: '4px 0 0 4px',
              },
            }}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.telegramLink.replace(/\s/g, '')}
            sx={{ flex: 1, minHeight: '90px' }}
            helperText={touched.telegramLink ? errors.telegramLink : ''}
            error={Boolean(errors.telegramLink) && touched.telegramLink}
          />

          <ButtonGradientDark
            disabled={socialButtonDisabled}
            onClick={() => handleUserUpdate()}
            fullWidth
          >
            Change Details
          </ButtonGradientDark>

          <Button
            onClick={() => dispatch(setSocialStep(SOCIAL_STEPS.SECOND_STEP))}
            sx={{ marginTop: toRem(8) }}
            fullWidth
          >
            I don't want to change my socials
          </Button>
        </Box>
      );
    }
    default: {
      return (
        <Box textAlign="left">
          <Typography
            sx={{
              fontSize: { md: toRem(24), xs: toRem(20) },
              mb: toRem(16),
            }}
            variant="h3"
          >
            Are you sure?
          </Typography>

          <Typography mb={toRem(32)}>
            Connecting your Telegram and Discord accounts helps you maximize
            your trading opportunities and stay informed about the latest deals.
            It only takes a moment and significantly enhances your trading
            experience!
          </Typography>

          <Box sx={{ display: 'flex', gap: toRem(16), mb: toRem(32) }}>
            <Box>
              <VerifiedIcon />

              <Typography
                sx={{
                  fontSize: { md: toRem(20), xs: toRem(18) },
                  mb: toRem(8),
                }}
                variant="h4"
              >
                Verified Social Profiles
              </Typography>

              <Typography>
                Build trust with other users by showcasing your verified social
                profiles. A complete profile can lead to more successful and
                trustworthy transactions.
              </Typography>
            </Box>

            <Box>
              <AddCommentIcon />

              <Typography
                sx={{
                  fontSize: { md: toRem(20), xs: toRem(18) },
                  mb: toRem(8),
                }}
                variant="h4"
              >
                Enhanced Communication
              </Typography>

              <Typography>
                Seamlessly connect with other traders, making communication
                faster and more efficient. Get real-time updates and
                notifications about your listings and potential trades.
              </Typography>
            </Box>
          </Box>

          <ButtonGradientDark
            sx={{ mb: toRem(8) }}
            onClick={() => dispatch(setSocialStep(SOCIAL_STEPS.FIRST_STEP))}
            fullWidth
          >
            Update My Socials
          </ButtonGradientDark>

          <Button
            fullWidth
            onClick={() => {
              localStorage.setItem('socialSkipped', 'true');
              dispatch(hideLoginPopup());
            }}
          >
            I am sure I don't want to change my socials
          </Button>
        </Box>
      );
    }
  }
}

export default SocialConnection;
