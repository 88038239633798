import { TProject } from '@typescript/models/Project.model';
import { FormikProvider, useFormik } from 'formik';
import { InvestEthValidationSchema } from '@formik/modal/ModalValidation';
import { calculate } from '@utils/functions';
import { InvestmentEthProjectInitialValue } from '@formik/modal/ModalInitialData';
import {
  EthInvestmentModalStep,
  TCreateInvestmentEthProjectInitialValues,
} from '@typescript/TModalFormik';
import useEthTransfer from '@hooks/useEthTransfer';
import { useState } from 'react';
import { BLOCK_CONFIRMATIONS } from '@constants/CLimits';
import { useConfirmEthInvestmentMutation, useGetEligibleInvestmentQuery } from '@store/api/ethInvestmentApi';
import { Box, CircularProgress } from '@mui/material';

import useNotification from '@hooks/useNotification';
import InvestmentEthStep1Modal from './InvestmentEthStep1Modal';
import InvestmentEthStep2Modal from './InvestmentEthStep2Modal';
import TxInvestmentConfirmationModal from '../transactions/TxInvestmentConfirmationModal';
import TxSentModal from '../transactions/TxSentModal';
import WtiErrorModal from '../investment/WtiErrorModal';
import WtiSuccessModal from '../investment/WtiSuccessModal';
import SwitchToEthNetworkModal from '../SwitchToEthNetworkModal';
import ModalCom from '../ModalCom';

interface Props {
  step: EthInvestmentModalStep;
  setStep: (data: EthInvestmentModalStep | null) => void;
  project: TProject;
}

function InvestmentEthModals({ step, project, setStep }: Props) {
  const { ethInvestmentTransfer } = useEthTransfer();

  const { notifySuccess } = useNotification();

  const { isLoading, data } = useGetEligibleInvestmentQuery(
    {
      projectId: project.id,
    },
    { skip: step === EthInvestmentModalStep.SWITCH_CHAIN },
  );

  const [confirmEthTransaction] = useConfirmEthInvestmentMutation();

  const [hash, setHash] = useState('');

  const calculateInvestmentAmountRemaining = () => {
    const remainingInvestment = calculate(
      project.fundraisingTarget,
      '-',
      project.investmentAmount,
    );
    return remainingInvestment < 0 ? 0 : remainingInvestment;
  };

  const handelOnSubmit = async (
    values: TCreateInvestmentEthProjectInitialValues,
  ) => {
    try {
      setStep(EthInvestmentModalStep.APPROVE_TX);

      const txResponse = await ethInvestmentTransfer({
        acceptedTerms: values.acceptTerms,
        to: project.fee.lvWallet,
        projectId: project.id,
        investment: +values.totalCost,
      });

      setHash(txResponse.hash);
      setStep(EthInvestmentModalStep.TX_SEND);

      await txResponse.wait(BLOCK_CONFIRMATIONS);
      await confirmEthTransaction(txResponse.hash).unwrap();

      setStep(EthInvestmentModalStep.SUCCESS);
      notifySuccess('Investment completed');
    } catch (error) {
      setStep(EthInvestmentModalStep.ERROR);
      throw error;
    }
  };

  const formik = useFormik({
    initialValues: InvestmentEthProjectInitialValue,
    validationSchema: InvestEthValidationSchema(
      data?.eligibleToInvest || 0,
      project.minInvestment,
      data?.balance || 0,
      calculateInvestmentAmountRemaining(),
    ),
    onSubmit: (values) => handelOnSubmit(values),
  });

  if (isLoading) {
    return (
      <ModalCom
        open
        containerSx={{
          backgroundColor: 'transparent',
          borderWidth: 0,
          '::before': {
            content: '""',
            backgroundColor: 'transparent !important',
            backdropFilter: 'none',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </ModalCom>
    );
  }

  const displayModals = () => {
    switch (step) {
      case EthInvestmentModalStep.STEP_1:
        return (
          <InvestmentEthStep1Modal
            project={project}
            onClose={() => {
              setStep(null);
            }}
            maxInvestment={data?.eligibleToInvest || 0}
            holdingTier={data?.holdingTier || null}
            nftTier={data?.nftTier || null}
            balance={data?.balance || 0}
            next={() => {
              setStep(EthInvestmentModalStep.STEP_2);
            }}
          />
        );
      case EthInvestmentModalStep.STEP_2:
        return (
          <InvestmentEthStep2Modal
            project={project}
            next={() => { }}
            preview={() => {
              setStep(EthInvestmentModalStep.STEP_1);
            }}
            onClose={() => setStep(null)}
          />
        );
      case EthInvestmentModalStep.APPROVE_TX:
        return (
          <TxInvestmentConfirmationModal
            open
            tokenPrice={project.tokenPrice}
            onClose={() => setStep(null)}
            isEthProject
          />
        );
      case EthInvestmentModalStep.TX_SEND:
        return (
          <TxSentModal txHash={hash} isEth open onClose={() => setStep(null)} />
        );
      case EthInvestmentModalStep.ERROR:
        return (
          <WtiErrorModal errorModal closeErrorModal={() => setStep(null)} />
        );
      case EthInvestmentModalStep.SUCCESS:
        return (
          <WtiSuccessModal
            successModal
            closeSuccessModal={() => setStep(null)}
            project={project}
            currentTransactionHash={hash}
          />
        );
      case EthInvestmentModalStep.SWITCH_CHAIN:
        return (
          <SwitchToEthNetworkModal
            open
            onClose={() => {
              setStep(null);
            }}
            chainSwitched={() => {
              setStep(EthInvestmentModalStep.STEP_1);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <FormikProvider value={formik}>
      <>{displayModals()}</>
    </FormikProvider>
  );
}

export default InvestmentEthModals;
