import { Box, Typography, Tooltip } from '@mui/material';
import { TListing } from '@typescript/models/Listing.model';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { UpdateListingExpirationInitialValues } from '@formik/modal/ModalInitialData';
import { UpdateListingExpirationSchema } from '@formik/modal/ModalValidation';
import {
  CloseBtn,
  QuestionMarkStyled,
  HeaderModal,
  ModalH6Text,
  ExpireBox,
  ExpireInDaysButton,
  ExpireInText,
  ModalFooter,
} from '@styles/modal/SModal';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import { TUpdateListingExpirationInitialValues } from '@typescript/TModalFormik';
import ModalCom from '@components/modals/ModalCom';
import useListings from '@hooks/useListings';

import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import useNotification from '@hooks/useNotification';

interface Props {
  listing: TListing;
  openModal: boolean;
  closeModal: () => void;
}
function ModifyExpirationDate({ openModal, closeModal, listing }: Props) {
  const { updateExpiration } = useListings();

  const { notifyError, notifySuccess } = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { MAX_EXPIRE_IN_DAYS } = useListings();

  const expireInDaysArray = Array.from(
    { length: MAX_EXPIRE_IN_DAYS },
    (_, index) => index + 1,
  );

  const modifyExpirationDate = async (
    values: TUpdateListingExpirationInitialValues,
  ) => {
    try {
      const payload = {
        id: listing.id,
        expireIn: values.expireIn,
      };

      await updateExpiration(payload);
      notifySuccess('Listing Expiration Date Succesfully Updated!');
    } catch (error) {
      notifyError('Extend Listing Expiration Date action failed!');
      throw error;
    } finally {
      closeModal();
    }
  };

  const formik = useFormik({
    initialValues: UpdateListingExpirationInitialValues(listing),
    validationSchema: UpdateListingExpirationSchema(),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await modifyExpirationDate(values);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const { values, touched, submitForm, setValues, resetForm, setTouched } =
    formik;

  const increaseExpireDays = () => {
    setValues((prev) => ({ ...prev, expireIn: values.expireIn + 1 }));
  };

  const decreaseExpireDays = () => {
    setValues((prev) => ({ ...prev, expireIn: values.expireIn - 1 }));
  };

  useEffect(() => {
    setTouched({ ...touched });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    <IsLoadingCom />;
  }

  return (
    <ModalCom
      open={openModal}
      onClose={() => {
        closeModal();
        resetForm();
      }}
    >
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Extend The Expiration Date
          </Typography>
          <CloseBtn onClick={closeModal}>
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>

        <Box sx={{ p: '15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ModalH6Text mr={1}>
              {`Expire In (max. ${expireInDaysArray.length} days)`}
            </ModalH6Text>
            <Tooltip
              title="The period after your listing will expire and become inactive again."
              placement="right-end"
            >
              <QuestionMarkStyled />
            </Tooltip>
          </Box>

          <ExpireBox>
            <ExpireInDaysButton
              disableRipple
              onClick={decreaseExpireDays}
              disabled={values.expireIn <= 1}
            >
              <RemoveRoundedIcon />
            </ExpireInDaysButton>
            <ExpireInText>{values.expireIn}</ExpireInText>
            <ExpireInDaysButton
              disableRipple
              onClick={increaseExpireDays}
              disabled={values.expireIn >= expireInDaysArray.length}
            >
              <AddRoundedIcon />
            </ExpireInDaysButton>
          </ExpireBox>
        </Box>

        <ModalFooter>
          <ButtonGradientDark
            fullWidth
            onClick={submitForm}
            disabled={isLoading}
          >
            Extend
          </ButtonGradientDark>
        </ModalFooter>
      </>
    </ModalCom>
  );
}

export default ModifyExpirationDate;
