import { Box, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonGradientDark, DeleteButton } from '@styles/shared/SButtons';
import { CloseBtn, HeaderModal, ModalFooter } from '@styles/modal/SModal';
import ModalCom from '@components/modals/ModalCom';
import { TOffer } from '@typescript/models/Offer.model';
import useOffers from '@hooks/useOffers';
import { toRem } from '@utils/functions';
import useNotification from '@hooks/useNotification';
import { useState } from 'react';

interface Props {
  closeModal: () => void;
  openModal: boolean;
  currentOffer: TOffer;
}

function DeleteOfferModal({ closeModal, openModal, currentOffer }: Props) {
  const { deleteOffer } = useOffers();

  const { notifyError, notifySuccess } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const handleDeleteOffer = async () => {
    try {
      setIsLoading(true);
      await deleteOffer(currentOffer);
      closeModal();
      setIsLoading(false);
      notifySuccess('Offer Deleted!');
    } catch (error) {
      setIsLoading(false);
      notifyError('Delete offer failed!');
      throw error;
    }
  };

  return (
    <ModalCom open={openModal} onClose={() => closeModal()}>
      <Box>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Confirmation
          </Typography>
          <CloseBtn
            onClick={() => {
              closeModal();
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>
        <Typography
          variant="h6"
          sx={{
            fontSize: '17px',
            p: '15px',
            color: theme.palette.text.secondary,
          }}
        >
          Are you sure you want to delete this offer?
        </Typography>
        <ModalFooter
          sx={{
            display: 'flex',
            gap: '12px',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <DeleteButton
            variant="outlined"
            onClick={handleDeleteOffer}
            fullWidth
            disabled={isLoading}
            sx={{
              padding: {
                xs: `${toRem(18)} ${toRem(16)}`,
                sm: `${toRem(11)} ${toRem(16)}`,
              },
            }}
          >
            Delete
          </DeleteButton>
          <ButtonGradientDark
            variant="contained"
            onClick={() => closeModal()}
            fullWidth
            disabled={isLoading}
            sx={{
              boxShadow: 'none',
              padding: {
                xs: `${toRem(18)} ${toRem(16)}`,
                sm: `${toRem(11)} ${toRem(16)}`,
              },
            }}
          >
            Cancel
          </ButtonGradientDark>
        </ModalFooter>
      </Box>
    </ModalCom>
  );
}

export default DeleteOfferModal;
