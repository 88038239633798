import { Box, Typography, Button, Link, useTheme } from '@mui/material';
import { TOffer } from '@typescript/models/Offer.model';
import CloseIcon from '@mui/icons-material/Close';
import useUtils from '@hooks/useUtils';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import {
  CloseBtn,
  HeaderModal,
  ModalFooter,
  ModalH4Text,
  ModalH6Text,
  ModalDetailsBox,
} from '@styles/modal/SModal';
import ModalCom from '@components/modals/ModalCom';
import { OfferStatus } from '@constants/COffer';
import { formatNr, sliceWalletAddress, toRem } from '@utils/functions';
import { border1pxPrimaryLight } from '@styles/shared/SCss';
import useNotification from '@hooks/useNotification';
import { useState } from 'react';
import { setStatusOfferZod } from '@typescript/dtos/offer/updateOffer.dto';
import { useUpdateOfferStatusMutation } from '@store/api/offerApi';

interface Props {
  openModal: boolean;
  closeModal: () => void;
  currentOffer: TOffer;
  newStatus: OfferStatus;
}

function ConfirmationOfferModal({
  openModal,
  closeModal,
  newStatus,
  currentOffer,
}: Props) {
  const text = newStatus === OfferStatus.ACCEPTED ? 'accept' : 'reject';

  const [updateOfferStatus] = useUpdateOfferStatusMutation();

  const { serverNetwork } = useUtils();
  const { notifySuccess } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const networkLink =
    serverNetwork === 'bsc'
      ? 'https://bscscan.com/address/'
      : 'https://testnet.bscscan.com/address/';

  const handleYesBtn = async () => {
    setIsLoading(true);
    await updateOfferStatus(
      setStatusOfferZod.parse({
        offerStatus: newStatus,
        id: currentOffer.id,
      }),
    ).unwrap();
    setIsLoading(false);
    closeModal();
    notifySuccess(`Offer ${text}ed!`);
  };

  const cancelButtonStyled = {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.text.primary,
    fontWeight: 400,
    border: border1pxPrimaryLight,
    padding: {
      xs: `${toRem(18)} ${toRem(16)}`,
      sm: `${toRem(11)} ${toRem(16)}`,
    },
    ':hover': {
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.text.primary,
    },
  };

  return (
    <ModalCom open={openModal} onClose={closeModal}>
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Transaction Confirmation
          </Typography>
          <CloseBtn
            onClick={() => {
              closeModal();
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>

        <Box p="15px">
          <ModalDetailsBox>
            <Box
              p="15px 15px 14px"
              sx={{ borderBottom: border1pxPrimaryLight }}
            >
              <ModalH4Text>Summary</ModalH4Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  borderRight: border1pxPrimaryLight,
                  p: '15px',
                  minWidth: '133px',
                }}
              >
                <ModalH6Text>Project Name</ModalH6Text>
                <ModalH6Text>Amount</ModalH6Text>
                <ModalH6Text>Asked Price</ModalH6Text>
                <ModalH6Text>Total Listing Cost</ModalH6Text>
                <ModalH6Text>Offered Price</ModalH6Text>
                <ModalH6Text>Total Offer Cost</ModalH6Text>
                <ModalH6Text>Listing User</ModalH6Text>
                <ModalH6Text>Accepted Coin</ModalH6Text>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: '10px',
                  p: '15px',
                }}
              >
                <Box
                  sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
                >
                  <img
                    src={currentOffer.listing.project.image}
                    style={{ borderRadius: '6px' }}
                    width={20}
                    height={20}
                    alt="project"
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 400,
                      fontSize: { xs: '13px', xsm: '15px' },
                    }}
                  >
                    {currentOffer.listing.project.name}
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(currentOffer.listing.amount)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(currentOffer.listing.tokenPrice, true)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(currentOffer.listing.price, true)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(currentOffer.offeredTokenPrice, true)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(+currentOffer.offeredPrice, true)}
                </Typography>
                <Link
                  href={`${networkLink}${currentOffer.listing.user.ethAddress}`}
                  target="_blank"
                  sx={{ fontSize: { xs: '13px', xsm: '15px' } }}
                  aria-label="wallet address"
                >
                  {sliceWalletAddress(currentOffer.listing.user.ethAddress)}
                </Link>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {currentOffer.offerCoin}
                </Typography>
              </Box>
            </Box>
          </ModalDetailsBox>
        </Box>

        <ModalFooter
          sx={{
            display: 'flex',
            gap: '12px',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Button
            variant="outlined"
            onClick={closeModal}
            fullWidth
            disabled={isLoading}
            sx={{ ...cancelButtonStyled }}
          >
            Cancel
          </Button>
          <ButtonGradientDark
            variant="contained"
            onClick={handleYesBtn}
            fullWidth
            disabled={isLoading}
            sx={{
              textTransform: 'capitalize',
              boxShadow: 'none',
              padding: {
                xs: `${toRem(18)} ${toRem(16)}`,
                sm: `${toRem(11)} ${toRem(16)}`,
              },
            }}
          >
            {text}
          </ButtonGradientDark>
        </ModalFooter>
      </>
    </ModalCom>
  );
}

export default ConfirmationOfferModal;
