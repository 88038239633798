import { Typography, Box, Button, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFormikContext } from 'formik';
import { formatNr } from '@utils/functions';
import ModalCom from '@components/modals/ModalCom';
import {
  HeaderModal,
  CloseBtn,
  CheckBoxStyled,
  StepsIndicator,
  ModalH4Text,
  ModalDetailsBox,
  ModalH6Text,
  ErrorListTooltip,
  ErrorListItemTooltip,
} from '@styles/modal/SModal';
import { BuyGradientDark } from '@styles/shared/SButtons';
import { TCreateWTIListingInitialValues } from '@typescript/TModalFormik';
import useStyles from '@hooks/useStyles';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useState } from 'react';
import { TProject } from '@typescript/models/Project.model';

interface Props {
  step2Modal: boolean;
  closeStep2Modal: () => void;
  openWtiStep1Modal: () => void;
  changeSteps: (number: 1 | 2 | 3) => void;
  project: TProject;
  feeInPercentage: number;
}

function WtiStep2Modal({
  step2Modal,
  closeStep2Modal,
  openWtiStep1Modal,
  changeSteps,
  project,
  feeInPercentage,
}: Props) {
  const {
    values,
    touched,
    errors,
    submitForm,
    setValues,
    setTouched,
    resetForm,
  } = useFormikContext<TCreateWTIListingInitialValues>();

  const [scrolledDown, setScrolledDown] = useState(false);

  const { border1pxPrimaryLight } = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const whiteTextColor = theme.palette.text.primary;

  const invalidAcceptTerms = Boolean(errors.acceptTerms && touched.acceptTerms);

  const validAcceptTerms = Boolean(!errors.acceptTerms && touched.acceptTerms);

  const handlePreviousStep = () => {
    closeStep2Modal();
    openWtiStep1Modal();
    changeSteps(1);
  };

  const createWtiAllocation = () => {
    setIsLoading(true);
    submitForm();
    setIsLoading(false);
  };

  const handleCheckBox = () => {
    setTouched({ ...touched, acceptTerms: true });
    setValues((prev) => ({
      ...prev,
      acceptTerms: !prev.acceptTerms,
    }));
  };

  const termsAndCondition = () => {
    return (
      <Box
        sx={{
          maxWidth: { xs: '520px', xsm: '420px' },
          width: '100%',
          height: '150px',
          overflowY: 'scroll',
          position: 'relative',
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'text.secondary',
            borderRadius: '6px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
        }}
        onScroll={(e) => {
          const target = e.target as HTMLDivElement;
          if (!scrolledDown) {
            setScrolledDown(
              target.scrollHeight - target.scrollTop <= target.clientHeight + 1,
            );
          }
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: { xs: '16px', xsm: '18px' }, mb: '6px' }}
        >
          Terms and Conditions
        </Typography>

        <ModalH4Text>
          You understand that this is not investment advice, legal advice,
          business advice, financial, or advice of any kind. You also understand
          that this is not an investment service, legal service, or financial
          service.
        </ModalH4Text>
        <br />
        <ModalH4Text>
          You understand and accept all risks related to ICOs and
          cryptocurrencies, and you agree never to contribute more
          cryptocurrency than you can afford to lose.
        </ModalH4Text>
        <br />
        <ModalH4Text>
          You understand and accept that the Legion Venture team is not
          responsible for any losses that might occur.
        </ModalH4Text>
        <br />
        <ModalH4Text>
          You agree that you have reached the legal age in your country of 18 or
          21 years.
        </ModalH4Text>
        <br />
        <ModalH4Text>
          You are not a resident and/or holding a passport issued by any of the
          countries that are excluded from the token sale.
        </ModalH4Text>

        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'transparent',
            backdropFilter: 'blur(20px)',
            py: '15px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckBoxStyled
              disabled={!scrolledDown}
              checked={values.acceptTerms}
              onClick={handleCheckBox}
              disableRipple
              checkedIcon={<CheckRoundedIcon fontSize="small" />}
              sx={{
                borderColor: invalidAcceptTerms
                  ? theme.palette.error.main
                  : theme.palette.text.secondary,
              }}
            />
            <ModalH4Text ml={1} color={whiteTextColor}>
              I have seen the disclaimer, I agree with the Terms.
            </ModalH4Text>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <ModalCom
      open={step2Modal}
      onClose={() => {
        closeStep2Modal();
        resetForm();
      }}
    >
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            New Investment
          </Typography>
          <StepsIndicator sx={{ ml: '9px' }}>Step 2 of 2 </StepsIndicator>
          <CloseBtn
            onClick={() => {
              closeStep2Modal();
              resetForm();
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>

        <Box sx={{ p: '15px', pb: 0 }}>
          <ModalDetailsBox sx={{ mb: '15px' }}>
            <Box
              p="15px 15px 14px"
              sx={{ borderBottom: border1pxPrimaryLight }}
            >
              <ModalH4Text>Investment Summary</ModalH4Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  borderRight: border1pxPrimaryLight,
                  p: '15px',
                  minWidth: { xs: '110px', sm: '124px' },
                }}
              >
                <ModalH6Text>Project Name</ModalH6Text>
                <ModalH6Text>Token Amount</ModalH6Text>
                <ModalH6Text>Token Price</ModalH6Text>
                <ModalH6Text>Total Cost</ModalH6Text>
                <ModalH6Text>Fee</ModalH6Text>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: '10px',
                  p: '15px',
                }}
              >
                <Box
                  sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
                >
                  <img
                    src={project.image}
                    style={{ borderRadius: '6px' }}
                    width={20}
                    height={20}
                    alt="project"
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 400,
                      fontSize: { xs: '13px', xsm: '15px' },
                    }}
                  >
                    {project.name}
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(+values.tokenAmount)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  ${formatNr(project.currentPrice)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(+values.totalCost, true)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {feeInPercentage}%
                </Typography>
              </Box>
            </Box>
          </ModalDetailsBox>

          {termsAndCondition()}
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '0.5rem',
            p: '15px',
            borderTop: border1pxPrimaryLight,
          }}
        >
          <Button
            onClick={handlePreviousStep}
            variant="outlined"
            sx={{
              padding: '0 0.4rem',
              borderColor: theme.palette.text.secondary,
              color: theme.palette.text.secondary,
              ':hover': {
                backgroundColor: theme.palette.background.default,
                color: whiteTextColor,
              },
            }}
          >
            <ArrowBackIcon
              sx={{
                width: 25,
                height: 25,
                color: 'inherit',
              }}
            />
          </Button>
          <BuyGradientDark
            onClick={createWtiAllocation}
            disabled={!validAcceptTerms || isLoading}
          >
            Pay Now
          </BuyGradientDark>
        </Box>
        {invalidAcceptTerms && (
          <ErrorListTooltip>
            <ErrorListItemTooltip>{errors.acceptTerms}</ErrorListItemTooltip>
          </ErrorListTooltip>
        )}
      </>
    </ModalCom>
  );
}
export default WtiStep2Modal;
