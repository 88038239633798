import { TradeModalStep, TransferType } from '@constants/CTransfer';
import { useState } from 'react';
import { TOffer } from '@typescript/models/Offer.model';
import { calculateAmountAfterFee } from '@utils/calculateAmountAfterFee';
import useUtils from '@hooks/useUtils';
import TxSentModal from './TxSentModal';
import TradeTxSuccessModal, { TxSummary } from './TradeTxSuccessModal';
import TradeTxFailedModal from './TradeTxFailedModal';
import MakeOfferTransactionModal from '../offers/MakeOfferTransactionModal';
import TxApproveConfirmationModal from './TxApproveConfirmationModal';

interface Props {
  step: TradeModalStep;
  changeStep: (newStep: TradeModalStep) => void;
  offer: TOffer;
}

function OfferTxModals({ step, offer, changeStep }: Props) {
  const { transferType } = useUtils();
  const [currentTxHash, setCurrentTxHash] = useState<string | null>(null);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const { totalPrice: totalPriceWitFee } = calculateAmountAfterFee(
    +offer.offeredPrice,
  );

  const totalPrice =
    transferType === TransferType.OTC_TRANSFER_CONTRACT
      ? totalPriceWitFee
      : offer.offeredPrice;

  const onClose = () => {
    setCurrentTxHash(null);
    setErrorMessage('');
    changeStep(TradeModalStep.CLOSE);
  };

  const onSuccess = () => {
    changeStep(TradeModalStep.TX_SUCCESS);
  };

  const onError = (message: string) => {
    setErrorMessage(message);
    changeStep(TradeModalStep.TX_FAILED);
  };

  const beforeTxConfirmation = (hash: string) => {
    changeStep(TradeModalStep.TX_SENT);
    setCurrentTxHash(hash);
  };

  const onTxInitialization = () => {
    changeStep(TradeModalStep.TX_CONFIRMATION);
  };

  switch (step) {
    case TradeModalStep.BUY:
      return (
        <MakeOfferTransactionModal
          open
          onClose={onClose}
          offer={offer}
          onTxInitialization={onTxInitialization}
          beforeTxConfirmation={beforeTxConfirmation}
          onSuccess={onSuccess}
          onError={onError}
        />
      );
    case TradeModalStep.TX_CONFIRMATION:
      return (
        <TxApproveConfirmationModal
          open
          onClose={onClose}
          totalCost={+totalPrice}
        />
      );
    case TradeModalStep.TX_SENT: {
      if (!currentTxHash) {
        throw Error(`No tx hash found for in step TradeModalStep.TX_SENT`);
      }
      return <TxSentModal open onClose={onClose} txHash={currentTxHash} />;
    }
    case TradeModalStep.TX_SUCCESS: {
      if (currentTxHash) {
        const txSummary: TxSummary = {
          hash: currentTxHash,
          project: offer.listing.project,
          tokenAmount: offer.listing.amount,
          tokenPrice: offer.offeredTokenPrice,
          totalCost: +totalPrice,
        };

        return (
          <TradeTxSuccessModal open onClose={onClose} txSummary={txSummary} />
        );
      }
      return null;
    }

    case TradeModalStep.TX_FAILED:
      return <TradeTxFailedModal open onClose={onClose} error={errorMessage} />;
    default:
      return null;
  }
}

export default OfferTxModals;
