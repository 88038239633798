import { Typography, Box, Button, Link, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TAllocation } from '@typescript/models/Allocation.model';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as ReactLink } from 'react-router-dom';
import { useFormikContext } from 'formik';
import ModalCom from '@components/modals/ModalCom';
import {
  HeaderModal,
  CloseBtn,
  CheckBoxStyled,
  StepsIndicator,
  ModalH4Text,
  ModalDetailsBox,
  ModalH6Text,
  ErrorListTooltip,
  ErrorListItemTooltip,
} from '@styles/modal/SModal';
import { SellGradientDark } from '@styles/shared/SButtons';
import { TCreateWTSListingInitialValues } from '@typescript/TModalFormik';
import { calculate, formatNr } from '@utils/functions';
import { border1pxPrimaryLight } from '@styles/shared/SCss';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { TProject } from '@typescript/models/Project.model';
import { useGetAllocationByProjectQuery } from '@store/api/allocationApi';
import { idZod } from '@typescript/dtos/shared/id.dto';
import { useState } from 'react';

interface Props {
  openModal: boolean;
  closeModal: () => void;
  openWtsStep1Modal: () => void;
  changeSteps: (number: 1 | 2) => void;
  project: TProject;
}

function WtsStep2Modal({
  openModal,
  closeModal,
  openWtsStep1Modal,
  changeSteps,
  project,
}: Props) {
  const {
    values,
    touched,
    errors,
    submitForm,
    setValues,
    setTouched,
    resetForm,
  } = useFormikContext<TCreateWTSListingInitialValues>();

  const { data: allocation = {} as TAllocation } =
    useGetAllocationByProjectQuery(idZod.parse({ id: project.id }));

  const theme = useTheme();
  const whiteTextColor = theme.palette.text.primary;

  const invalidAcceptTerms = Boolean(errors.acceptTerms && touched.acceptTerms);
  const validAcceptTerms = Boolean(!errors.acceptTerms && touched.acceptTerms);

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    closeModal();
    resetForm();
  };

  const handlePreviousStep = () => {
    closeModal();
    openWtsStep1Modal();
    changeSteps(1);
  };

  const createWtsListing = () => {
    setIsLoading(true);
    submitForm();
  };

  const handleCheckBox = () => {
    setTouched({ ...touched, acceptTerms: true });
    setValues((prev) => ({
      ...prev,
      acceptTerms: !prev.acceptTerms,
    }));
  };

  const getAmountInPercentage = () => {
    let percentage = calculate(
      +values.tokenAmount,
      '%?',
      allocation.lockedTokens,
    );
    percentage = +formatNr(percentage);
    return percentage;
  };

  return (
    <ModalCom open={openModal} onClose={handleCloseModal}>
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Create a WTS listing
          </Typography>
          <StepsIndicator sx={{ ml: '9px' }}>Step 2 of 2 </StepsIndicator>
          <CloseBtn onClick={handleCloseModal}>
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>

        <Box p="15px">
          <ModalDetailsBox>
            <Box
              p="15px 15px 14px"
              sx={{ borderBottom: border1pxPrimaryLight }}
            >
              <ModalH4Text>WTS Listing Summary</ModalH4Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  borderRight: border1pxPrimaryLight,
                  p: '15px',
                  minWidth: { xs: '110px', sm: '124px' },
                }}
              >
                <ModalH6Text>Project Name</ModalH6Text>
                <ModalH6Text>Token Amount</ModalH6Text>
                <ModalH6Text>Token Price</ModalH6Text>
                <ModalH6Text>Total Cost</ModalH6Text>
                <ModalH6Text>Negotiable</ModalH6Text>
                <ModalH6Text>Expires</ModalH6Text>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: '10px',
                  p: '15px',
                }}
              >
                <Box
                  sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
                >
                  <img
                    src={allocation.project.image}
                    style={{ borderRadius: '6px' }}
                    width={20}
                    height={20}
                    alt="project"
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 400,
                      fontSize: { xs: '13px', xsm: '15px' },
                    }}
                  >
                    {allocation.project.name}
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(+values.tokenAmount)} ({getAmountInPercentage()}% of
                  your allocation)
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(+values.tokenPrice, true)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(+values.totalCost, true)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {values.negotiable ? 'Yes' : 'No'}
                </Typography>
                {/* <Typography
              variant="h4"
              sx={{
                fontWeight: 400,
                fontSize: { xs: '13px', xsm: '16px' },
              }}
            >
              10%
            </Typography> */}
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  In {values.expireIn} days
                </Typography>
              </Box>
            </Box>
          </ModalDetailsBox>

          <Box
            sx={{
              paddingTop: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckBoxStyled
                checked={values.acceptTerms}
                onClick={handleCheckBox}
                disableRipple
                checkedIcon={<CheckRoundedIcon fontSize="small" />}
                sx={{
                  borderColor: invalidAcceptTerms
                    ? theme.palette.error.main
                    : theme.palette.text.secondary,
                }}
              />
              <ModalH4Text ml={1}>
                I have seen the disclaimer, I agree with the{' '}
                <Link
                  component={ReactLink}
                  sx={{
                    textDecorationColor: whiteTextColor,
                    color: whiteTextColor,
                    fontSize: { xs: '12px', xsm: '15px' },
                  }}
                  to="/terms"
                  aria-label="terms"
                >
                  terms.
                </Link>
                <Box component="span" color={whiteTextColor}>
                  *
                </Box>
              </ModalH4Text>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '0.5rem',
            p: '15px',
            borderTop: border1pxPrimaryLight,
          }}
        >
          <Button
            onClick={handlePreviousStep}
            variant="outlined"
            sx={{
              padding: '0 0.4rem',
              borderColor: theme.palette.text.secondary,
              color: theme.palette.text.secondary,
              ':hover': {
                backgroundColor: theme.palette.background.default,
                color: whiteTextColor,
              },
            }}
          >
            <ArrowBackIcon
              sx={{
                width: 25,
                height: 25,
                color: 'inherit',
              }}
            />
          </Button>
          <SellGradientDark
            onClick={createWtsListing}
            disabled={!validAcceptTerms || isLoading}
          >
            Sell
          </SellGradientDark>
        </Box>
        {invalidAcceptTerms && (
          <ErrorListTooltip>
            <ErrorListItemTooltip>{errors.acceptTerms}</ErrorListItemTooltip>
          </ErrorListTooltip>
        )}
      </>
    </ModalCom>
  );
}
export default WtsStep2Modal;
