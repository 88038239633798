import { Box, Link, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { TListing } from '@typescript/models/Listing.model';
import CloseIcon from '@mui/icons-material/Close';
import { TCoin } from '@typescript/TCoin';
import useUtils from '@hooks/useUtils';
import {
  CloseBtn,
  CurrentPriceBox,
  ErrorListItemTooltip,
  ErrorListTooltip,
  HeaderModal,
  ModalFooter,
  ModalH6Text,
} from '@styles/modal/SModal';
import ModalCom from '@components/modals/ModalCom';
import {
  calculateProfitLoss,
  cryptoFormat,
  formatNr,
  sliceWalletAddress,
  toRem,
} from '@utils/functions';
import { BuyGradientDark } from '@styles/shared/SButtons';
import { border1pxPrimaryLight } from '@styles/shared/SCss';
import useUser from '@hooks/useUser';
import { calculateAmountAfterFee } from '@utils/calculateAmountAfterFee';
import useTransfer from '@hooks/useTransfer';
import { MetamaskError } from '@utils/errors';
import { TransferType } from '@constants/CTransfer';

interface Props {
  listing: TListing;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: (message: string) => void;
  onTxInitialization: () => void;
  beforeTxConfirmation: (hash: string) => void;
}

function BuyWTBModal({
  listing,
  onClose,
  open,
  onSuccess,
  onError,
  beforeTxConfirmation,
  onTxInitialization,
}: Props) {
  const { userCoins } = useUser();

  const { getBscAddressLink, transferType } = useUtils();

  const { wtbTransfer } = useTransfer();

  const [isLoading, setIsLoading] = useState(false);

  const [userCanBuy, setUserCanBuy] = useState<boolean>(false);

  const [listingAcceptedCoin, setListingAcceptedCoin] = useState<
    TCoin | undefined
  >(undefined);

  const makeTransaction = async () => {
    try {
      setIsLoading(true);
      onTxInitialization();
      await wtbTransfer(listing, beforeTxConfirmation);
      onSuccess();
      setIsLoading(false);
    } catch (e) {
      const error = e as MetamaskError;
      if (error?.code === 4001) {
        onError('Transaction rejected.');
      } else {
        onError('Something went wrong..');
      }
      setIsLoading(false);
      throw error;
    }
  };

  const { totalPrice: totalPriceWitFee, feeOfListingPrice } =
    calculateAmountAfterFee(listing.price);

  const totalPrice =
    transferType === TransferType.OTC_TRANSFER_CONTRACT
      ? totalPriceWitFee
      : listing.price;

  const feeText =
    transferType === TransferType.OTC_TRANSFER_CONTRACT
      ? `${formatNr(feeOfListingPrice, true)} Fee`
      : '';

  const getModalAskingPrice = (currentListing: TListing) => {
    const style = {
      fontWeight: 400,
      paddingTop: { xs: '0.5rem', xsm: '1rem' },
      fontSize: { xs: '14px', xsm: '16px' },
    };

    return (
      <Tooltip title={currentListing.tokenPrice} placement="top-start">
        <Box sx={{ display: 'flex', gap: 1 }}>
          {cryptoFormat(currentListing.tokenPrice, style)}
          {calculateProfitLoss(
            currentListing.project.currentPrice,
            currentListing.tokenPrice,
            style,
          )}
        </Box>
      </Tooltip>
    );
  };

  useEffect(() => {
    if (listing.acceptedCoin && userCoins) {
      const listingCoin = userCoins.find(
        ({ name }) => name === listing.acceptedCoin,
      );

      setUserCanBuy((listingCoin?.balance ?? 0) >= totalPrice);

      setListingAcceptedCoin(listingCoin);
    }
  }, [listing.acceptedCoin, totalPrice, userCoins]);

  return (
    <ModalCom open={open} onClose={onClose}>
      <Box>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Buy
          </Typography>
          <CloseBtn onClick={onClose}>
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>
        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <Box>
              <ModalH6Text>Project name</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                }}
              >
                <img
                  src={listing.project.image}
                  style={{ borderRadius: '6px' }}
                  width={24}
                  height={24}
                  alt="project"
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: '13px', xsm: '15px' },
                    fontWeight: 400,
                    paddingLeft: '0.5rem',
                  }}
                >
                  {listing.project.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Current Price</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    paddingRight: '0.3rem',
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  <CurrentPriceBox>
                    {cryptoFormat(listing.project.currentPrice)}
                  </CurrentPriceBox>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <ModalH6Text>Asking Price</ModalH6Text>
              <CurrentPriceBox>{getModalAskingPrice(listing)}</CurrentPriceBox>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
            borderBottom: border1pxPrimaryLight,
            borderTop: border1pxPrimaryLight,
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <ModalH6Text sx={{ marginBottom: '8px' }}>Listing User</ModalH6Text>
            <Link
              href={getBscAddressLink(listing.user.ethAddress)}
              target="_blank"
              sx={{ fontSize: { xs: '13px', xsm: '15px' } }}
              aria-label="wallet address"
            >
              {sliceWalletAddress(listing.user.ethAddress)}
            </Link>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Token Amount</ModalH6Text>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '14px', xsm: '15px' },
                  paddingTop: '10px',
                }}
              >
                {formatNr(listing.amount, undefined, 0)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box>
              <ModalH6Text>Total Cost</ModalH6Text>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '13px', xsm: '15px' },
                  paddingTop: '10px',
                }}
              >
                {formatNr(totalPrice, true)}
                {feeText}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box p="15px">
          <ModalH6Text marginBottom="8px">
            Your {listing.acceptedCoin} Balance
          </ModalH6Text>
          <Typography variant="h4" sx={{ fontWeight: 400 }}>
            {listingAcceptedCoin?.balance
              ? formatNr(listingAcceptedCoin?.balance, true)
              : 0}
          </Typography>
        </Box>
        <ModalFooter>
          <BuyGradientDark
            onClick={makeTransaction}
            disabled={!userCanBuy || isLoading}
            sx={{
              padding: {
                xs: `${toRem(18)} ${toRem(16)}`,
                sm: `${toRem(11)} ${toRem(16)}`,
              },
            }}
          >
            Buy
          </BuyGradientDark>
        </ModalFooter>
        {!userCanBuy && (
          <ErrorListTooltip>
            <ErrorListItemTooltip>
              You do not have enough money!
            </ErrorListItemTooltip>
          </ErrorListTooltip>
        )}
      </Box>
    </ModalCom>
  );
}
export default BuyWTBModal;
