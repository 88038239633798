import { Typography, Box } from '@mui/material';
import ModalCom from '@components/modals/ModalCom';
import { ModalH6Text } from '@styles/modal/SModal';
import Lottie from 'lottie-react';
import LogoLoadingAnimation from '@animations/lv-loading-animation.json';

import { ButtonGradientDark } from '@styles/shared/SButtons';
import useUtils from '@hooks/useUtils';

interface Props {
  open: boolean;
  onClose: () => void;
  txHash: string;
  isEth?: boolean;
}

function TxSentModal({ open, onClose, txHash, isEth }: Props) {
  const { getBscTxLink, getEthTxLink } = useUtils();

  return (
    <ModalCom open={open} onClose={onClose}>
      <Box
        sx={{
          p: '36px 68px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ maxWidth: '76px', width: '100%' }}>
          <Lottie
            animationData={LogoLoadingAnimation}
            loop
            style={{ marginBottom: '24px' }}
          />
        </Box>
        <Typography variant="h4" sx={{ mb: '10px' }}>
          Transaction sent to the blockchain
        </Typography>
        <ModalH6Text sx={{ mb: '12px' }}>
          Waiting for confirmation...
        </ModalH6Text>
        {isEth ? (
          <ButtonGradientDark
            fullWidth
            sx={{ mb: '12px' }}
            onClick={() => {
              const link = getEthTxLink(txHash);
              window.open(link, '_blank');
            }}
          >
            View on EthScan
          </ButtonGradientDark>
        ) : (
          <ButtonGradientDark
            fullWidth
            sx={{ mb: '12px' }}
            onClick={() => {
              const link = getBscTxLink(txHash);
              window.open(link, '_blank');
            }}
          >
            View on BscScan
          </ButtonGradientDark>
        )}
      </Box>
    </ModalCom>
  );
}

export default TxSentModal;
