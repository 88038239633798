import {
  Box,
  Typography,
  useTheme,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@mui/material';
import { TCoin } from '@typescript/TCoin';
import CloseIcon from '@mui/icons-material/Close';
import { useFormikContext } from 'formik';

import { TCreateWTBListingInitialValues } from '@typescript/TModalFormik';
import {
  CloseBtn,
  FieldStyled,
  HeaderModal,
  QuestionMarkStyled,
  StepsIndicator,
  ModalH6Text,
  CurrentPriceBox,
  ModalFooter,
  ExpireBox,
  ExpireInDaysButton,
  ErrorListTooltip,
  ErrorListItemTooltip,
  ExpireInText,
  ModalH4Text,
} from '@styles/modal/SModal';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import ModalCom from '@components/modals/ModalCom';
import { Coin } from '@constants/CCoin';
import {
  calculate,
  cryptoFormat,
  formatNr,
  formatModalDecimals,
} from '@utils/functions';
import useListings from '@hooks/useListings';
import useStyles from '@hooks/useStyles';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useEffect } from 'react';
import { Svgs } from '@utils/svgs';
import useUser from '@hooks/useUser';
import { TProject } from '@typescript/models/Project.model';

interface Props {
  step1Modal: boolean;
  closeStep1Modal: () => void;
  changeModalSteps: (number: 1 | 2) => void;
  openWtbStep2Modal: () => void;
  project: TProject;
  hightestLockedTokens: number;
  userCoins?: TCoin[];
}

function WtbStep1Modal({
  step1Modal,
  closeStep1Modal,
  changeModalSteps,
  openWtbStep2Modal,
  project,
  hightestLockedTokens,
  userCoins,
}: Props) {
  const theme = useTheme();

  const {
    values,
    handleBlur,
    touched,
    errors,
    setValues,
    setTouched,
    resetForm,
  } = useFormikContext<TCreateWTBListingInitialValues>();

  const { userInformation } = useUser();

  const invalidTokenAmount = Boolean(errors.tokenAmount && touched.tokenAmount);
  const invalidTokenPrice = Boolean(errors.tokenPrice && touched.tokenPrice);
  const invalidTotalCost = Boolean(errors.totalCost && touched.totalCost);
  const invalidCoins = Boolean(errors.coins && touched.coins);

  const validTokenAmount = Boolean(!errors.tokenAmount && touched.tokenAmount);
  const validTokenPrice = Boolean(!errors.tokenPrice && touched.tokenPrice);
  const validTotalCost = Boolean(!errors.totalCost && touched.totalCost);
  const validCoins = Boolean(!errors.coins && touched.coins);

  const shouldDisplayError =
    invalidTokenAmount || invalidTokenPrice || invalidTotalCost || invalidCoins;

  const isWTBStep1Valid =
    validTokenAmount && validTokenPrice && validTotalCost && validCoins;

  const { MAX_EXPIRE_IN_DAYS } = useListings();

  const expireInDaysArray = Array.from(
    { length: MAX_EXPIRE_IN_DAYS },
    (_, index) => index + 1,
  );

  const { border1pxPrimaryLight } = useStyles();

  const handleClickCoinButton = (currentCoin: Coin) => {
    const newCoins = [...values.coins];
    const coinIndex = values.coins.indexOf(currentCoin);

    if (coinIndex > -1) {
      newCoins.splice(coinIndex, 1);
    } else {
      newCoins.push(currentCoin);
    }
    setValues((prev) => ({ ...prev, coins: newCoins }));
  };

  const handleChangeTokenPrice = (newTokenPrice: string) => {
    setTouched({ ...touched, totalCost: true, tokenPrice: true });

    if (newTokenPrice === '') {
      setValues((prev) => ({
        ...prev,
        totalCost: '',
        tokenPrice: '',
      }));
      return;
    }

    const newTokenPriceFormatted = formatModalDecimals(
      newTokenPrice.toString(),
    );

    if (values.tokenAmount) {
      const newTotalCost = calculate(
        +newTokenPriceFormatted,
        '*',
        +values.tokenAmount,
      );

      const newTotalCostFormatted = formatModalDecimals(
        newTotalCost.toString(),
      );

      setValues((prev) => ({
        ...prev,
        totalCost: newTotalCostFormatted,
        tokenPrice: newTokenPriceFormatted,
      }));

      return;
    }

    if (values.totalCost) {
      const newTokenAmount = calculate(
        +values.totalCost,
        '/',
        +values.tokenPrice,
      );
      const newTokenAmountFormatted = formatModalDecimals(
        newTokenAmount.toString(),
      );

      setTouched({ tokenAmount: true });
      setValues((prev) => ({
        ...prev,
        tokenAmount: newTokenAmountFormatted,
        tokenPrice: formatModalDecimals(newTokenPrice),
      }));
      return;
    }

    setValues((prev) => ({
      ...prev,
      tokenPrice: newTokenPriceFormatted,
    }));
  };

  const handleChangeTokenAmount = (newAmount: string) => {
    setTouched({ ...touched, totalCost: true, tokenAmount: true });

    if (newAmount === '') {
      setValues((prev) => ({
        ...prev,
        tokenAmount: '',
        totalCost: '',
      }));
      return;
    }

    const newAmountFormatted = formatModalDecimals(newAmount);

    if (values.tokenPrice === '') {
      setValues((prev) => ({
        ...prev,
        tokenAmount: newAmountFormatted,
      }));
      return;
    }

    const newTotalCost = calculate(
      +values.tokenPrice,
      '*',
      +newAmountFormatted,
    );
    const newTotalCostFormatted = formatModalDecimals(newTotalCost.toString());

    setValues((prev) => ({
      ...prev,
      tokenAmount: newAmountFormatted,
      totalCost: newTotalCostFormatted,
    }));
  };

  const handleChangeTotalCost = (newTotalCost: string) => {
    setTouched({ ...touched, tokenAmount: true, totalCost: true });
    if (newTotalCost === '') {
      setValues((prev) => ({
        ...prev,
        totalCost: '',
        tokenAmount: '',
      }));
      return;
    }

    const newTotalCostFormatted = formatModalDecimals(newTotalCost);

    if (values.tokenPrice === '') {
      setValues((prev) => ({
        ...prev,
        totalCost: newTotalCostFormatted,
      }));
      return;
    }

    const newTokenAmount = calculate(
      +newTotalCostFormatted,
      '/',
      +values.tokenPrice,
    );

    const newTokenAmountFormatted = formatModalDecimals(
      newTokenAmount.toString(),
    );

    setValues((prev) => ({
      ...prev,
      totalCost: newTotalCostFormatted,
      tokenAmount: newTokenAmountFormatted,
    }));
  };

  const handleNextStep = () => {
    if (isWTBStep1Valid) {
      closeStep1Modal();
      openWtbStep2Modal();
      changeModalSteps(2);
    } else {
      setTouched({
        tokenAmount: true,
        totalCost: true,
        tokenPrice: true,
        coins: true,
      });
    }
  };

  const increaseExpireDays = () => {
    setValues((prev) => ({ ...prev, expireIn: values.expireIn + 1 }));
  };

  const decreaseExpireDays = () => {
    setValues((prev) => ({ ...prev, expireIn: values.expireIn - 1 }));
  };

  const handleTelegramLink = (isAddingTelegram: boolean) => {
    if (!userInformation) {
      throw Error('No user Information found');
    }
    setTouched({ ...touched, telegramLink: true });
    if (isAddingTelegram) {
      setValues((prev) => ({
        ...prev,
        telegramLink: userInformation.telegramLink,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        telegramLink: '',
      }));
    }
  };

  const handleDiscord = (isAddingDiscord: boolean) => {
    if (!userInformation) {
      throw Error('No user Information found');
    }
    setTouched({ ...touched, discordName: true });
    if (isAddingDiscord) {
      setValues((prev) => ({
        ...prev,
        discordName: userInformation.discordName,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        discordName: '',
      }));
    }
  };

  useEffect(() => {
    if (
      userInformation?.telegramLink &&
      userInformation?.telegramLink.length > 4
    ) {
      handleTelegramLink(!values.telegramLink);
    }
    if (
      userInformation?.discordName &&
      userInformation?.discordName.length > 2
    ) {
      handleDiscord(!values.discordName);
    }

    setTouched({ ...touched, coins: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalCom
      open={step1Modal}
      onClose={() => {
        closeStep1Modal();
        resetForm();
      }}
    >
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Create a WTB listing
          </Typography>
          <StepsIndicator>Step 1 of 2 </StepsIndicator>
          <CloseBtn
            onClick={() => {
              closeStep1Modal();
              resetForm();
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>
        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <Box>
              <ModalH6Text>Project Name</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                }}
              >
                <img
                  src={project.image}
                  style={{ borderRadius: '6px' }}
                  width={24}
                  height={24}
                  alt="project"
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: '13px', xsm: '15px' },
                    fontWeight: 400,
                    paddingLeft: '0.5rem',
                  }}
                >
                  {project.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Maximum Balance</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '10px',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    paddingRight: '0.3rem',
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(hightestLockedTokens, undefined, 0)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <ModalH6Text>Current Price</ModalH6Text>
              <Tooltip title={project.currentPrice} placement="right-end">
                <CurrentPriceBox>
                  {cryptoFormat(project.currentPrice)}
                </CurrentPriceBox>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            paddingLeft: '15px',
            paddingRight: '15px',
            borderTop: border1pxPrimaryLight,
          }}
        >
          <Box
            sx={{
              paddingTop: { xs: '1rem', xsm: '18px' },
              display: 'flex',
              gap: '12px',
            }}
          >
            <FieldStyled
              id="tokenPrice"
              name="tokenPrice"
              label="Token Price"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              sx={{ flex: 1 }}
              type="number"
              value={values.tokenPrice}
              onBlur={handleBlur}
              onChange={(event) => handleChangeTokenPrice(event.target.value)}
              error={invalidTokenPrice}
              InputLabelProps={{ shrink: true }}
            />
            <FieldStyled
              id="tokenAmount"
              name="tokenAmount"
              label="Token Amount"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      marginBottom: '2px',
                      marginLeft: '0px',
                      '& .MuiTypography-root': { fontSize: '15px' },
                    }}
                  >
                    {project.symbol}
                  </InputAdornment>
                ),
              }}
              type="number"
              sx={{ flex: 1 }}
              value={values.tokenAmount}
              onBlur={handleBlur}
              onChange={(event) => handleChangeTokenAmount(event.target.value)}
              error={invalidTokenAmount}
              placeholder="0"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <FieldStyled
            sx={{ marginTop: '12px' }}
            id="totalCost"
            name="totalCost"
            type="number"
            label="Total Cost"
            onBlur={handleBlur}
            onChange={(event) => handleChangeTotalCost(event.target.value)}
            value={values.totalCost}
            error={invalidTotalCost}
            fullWidth
            InputLabelProps={{ shrink: true }}
            placeholder="0"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '8px 15px 13px 15px',
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
          }}
        >
          <ModalH6Text mr={1}>Original token price</ModalH6Text>
          <Tooltip title={project.tokenPrice} placement="right-end">
            <Box
              sx={{
                fontSize: '13px',
              }}
            >
              {formatNr(+project.tokenPrice, undefined, 0)}
            </Box>
          </Tooltip>
        </Box>
        <Box sx={{ p: '15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ModalH6Text mr={1}>Pay in</ModalH6Text>
            <Tooltip
              title="You can choose multiple stable coins"
              placement="right-end"
            >
              <QuestionMarkStyled />
            </Tooltip>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '0.7rem',
              paddingTop: '1rem',
            }}
          >
            {userCoins &&
              userCoins.map((currentCoin) => (
                <Box
                  key={currentCoin.name}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <ButtonGradientDark
                    variant={
                      values.coins.includes(currentCoin.name)
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => handleClickCoinButton(currentCoin.name)}
                  >
                    {currentCoin.name}
                  </ButtonGradientDark>
                  <Typography variant="h6" mt="8px">
                    {formatNr(currentCoin.balance, true)}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}
          >
            <ModalH6Text mr={1}>
              {`Expire In (max. ${expireInDaysArray.length} days`}
            </ModalH6Text>
            <Tooltip
              title="The period after your listing will expire and will be archived"
              placement="right-end"
            >
              <QuestionMarkStyled />
            </Tooltip>
          </Box>

          <ExpireBox sx={{ mb: '16px' }}>
            <ExpireInDaysButton
              disableRipple
              onClick={decreaseExpireDays}
              disabled={values.expireIn <= 1}
            >
              <RemoveRoundedIcon />
            </ExpireInDaysButton>
            <ExpireInText>{values.expireIn}</ExpireInText>
            <ExpireInDaysButton
              disableRipple
              onClick={increaseExpireDays}
              disabled={values.expireIn >= expireInDaysArray.length}
            >
              <AddRoundedIcon />
            </ExpireInDaysButton>
          </ExpireBox>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <ModalH4Text ml={1}>Display Social:</ModalH4Text>
            <Tooltip
              title={
                userInformation?.telegramLink &&
                userInformation?.telegramLink.length > 4
                  ? 'Add Telegram'
                  : "You don't have a Telegram link set"
              }
            >
              <IconButton
                sx={{
                  color:
                    userInformation?.telegramLink && values.telegramLink
                      ? 'text.primary'
                      : 'primary.dark',
                  '&:hover': {
                    color: 'text.primary',
                  },
                }}
                onClick={() => {
                  if (
                    userInformation?.telegramLink &&
                    userInformation?.telegramLink.length > 4
                  ) {
                    handleTelegramLink(!values.telegramLink);
                  }
                }}
              >
                <Svgs.TelegramIcon
                  color="inherit"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                userInformation?.discordName &&
                userInformation?.discordName.length > 2
                  ? 'Add Discord'
                  : "You don't have a Discord username set"
              }
            >
              <IconButton
                sx={{
                  color:
                    userInformation?.discordName && values.discordName
                      ? 'text.primary'
                      : 'primary.dark',
                  '&:hover': {
                    color: 'text.primary',
                  },
                }}
                onClick={() => {
                  if (
                    userInformation?.discordName &&
                    userInformation?.discordName?.length > 4
                  ) {
                    handleDiscord(!values.discordName);
                  }
                }}
              >
                <Svgs.DiscordIcon
                  color="inherit"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <ModalFooter>
          <ButtonGradientDark
            fullWidth
            onClick={handleNextStep}
            disabled={!isWTBStep1Valid}
          >
            Next step
          </ButtonGradientDark>
        </ModalFooter>
        {shouldDisplayError && (
          <ErrorListTooltip>
            {invalidTokenPrice && (
              <ErrorListItemTooltip>{errors.tokenPrice}</ErrorListItemTooltip>
            )}
            {invalidTokenAmount && (
              <ErrorListItemTooltip>{errors.tokenAmount}</ErrorListItemTooltip>
            )}
            {invalidTotalCost && (
              <ErrorListItemTooltip>{errors.totalCost}</ErrorListItemTooltip>
            )}
            {errors.coins && (
              <ErrorListItemTooltip>{errors.coins}</ErrorListItemTooltip>
            )}
          </ErrorListTooltip>
        )}
      </>
    </ModalCom>
  );
}
export default WtbStep1Modal;
