import { Typography, Box, Link, Tooltip } from '@mui/material';
import { TListing } from '@typescript/models/Listing.model';
import { useState, useEffect, useCallback } from 'react';
import { TCoin } from '@typescript/TCoin';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import useUtils from '@hooks/useUtils';
import { SellConfirmationValues } from '@formik/modal/ModalInitialData';
import { SellConfirmationValidationSchema } from '@formik/modal/ModalValidation';
import {
  CloseBtn,
  CurrentPriceBox,
  ErrorListItemTooltip,
  ErrorListTooltip,
  HeaderModal,
  ModalFooter,
  ModalH6Text,
  QuestionMarkStyled,
} from '@styles/modal/SModal';
import { ButtonGradientDark, SellGradientDark } from '@styles/shared/SButtons';
import ModalCom from '@components/modals/ModalCom';
import { Coin } from '@constants/CCoin';
import {
  calculateProfitLoss,
  cryptoFormat,
  formatNr,
  sliceWalletAddress,
  toRem,
} from '@utils/functions';
import { border1pxPrimaryLight } from '@styles/shared/SCss';
import useUser from '@hooks/useUser';
import { useGetAllocationByProjectQuery } from '@store/api/allocationApi';
import { idZod } from '@typescript/dtos/shared/id.dto';

interface Props {
  listing: TListing;
  closeModal: () => void;
  openModal: boolean;
  sellWTBListing: (acceptedCoin: Coin) => Promise<void>;
}

function SellWTBModal({
  listing,
  closeModal,
  openModal,
  sellWTBListing,
}: Props) {
  const { userCoins } = useUser();

  const { data: myAllocation } = useGetAllocationByProjectQuery(
    idZod.parse({ id: listing.project.id }),
  );

  const { serverNetwork } = useUtils();

  const [isLoading, setIsLoading] = useState(false);

  const [listingCoinsData, setListingCoinsData] = useState<TCoin[]>([]);

  const networkLink =
    serverNetwork === 'bsc'
      ? 'https://bscscan.com/address/'
      : 'https://testnet.bscscan.com/address/';

  const formik = useFormik({
    initialValues: SellConfirmationValues,
    validationSchema: SellConfirmationValidationSchema(listing?.amount || 0),
    onSubmit: async (values) => {
      if (values.selectedCoin !== '') {
        setIsLoading(true);
        await sellWTBListing(values.selectedCoin);
        setIsLoading(false);
      }
    },
  });

  const { values, errors, touched, handleSubmit, setValues, setTouched } =
    formik;

  const invalidCoins = Boolean(errors.selectedCoin && touched.selectedCoin);
  const disabledSellButton = Boolean(
    errors.selectedCoin || !touched.selectedCoin,
  );

  const setListingAvailableCoins = useCallback(() => {
    const listingCoinData: TCoin[] = [];
    listing.coins.forEach((listingCoin) => {
      if (userCoins) {
        const foundedCoin = userCoins.find(
          (userCoin) => userCoin.name === listingCoin,
        );
        if (foundedCoin) {
          listingCoinData.push(foundedCoin);
        }
      }
    });
    setListingCoinsData(listingCoinData);
  }, [listing.coins, userCoins]);

  const handleSelectedCoin = (coin: TCoin) => {
    setTouched({ ...touched, selectedCoin: true, coinValue: true });

    setValues((prev) => ({
      ...prev,
      selectedCoin: coin.name,
      coinValue: coin.balance,
    }));
  };

  const getModalAskingPrice = (currentListing: TListing) => {
    const style = {
      fontWeight: 400,
      paddingTop: { xs: '0.5rem', xsm: '1rem' },
      fontSize: { xs: '14px', xsm: '16px' },
    };

    return (
      <Tooltip title={currentListing.tokenPrice} placement="top-start">
        <Box sx={{ display: 'flex', gap: 1 }}>
          {cryptoFormat(currentListing.tokenPrice, style)}
          {calculateProfitLoss(
            currentListing.project.currentPrice,
            currentListing.tokenPrice,
            style,
          )}
        </Box>
      </Tooltip>
    );
  };

  useEffect(() => {
    setListingAvailableCoins();
  }, [setListingAvailableCoins]);

  useEffect(() => {
    if (myAllocation) {
      setValues((prev) => ({
        ...prev,
        amount: myAllocation.totalTokens,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myAllocation]);

  return (
    <ModalCom open={openModal} onClose={closeModal}>
      <Box>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Sell
          </Typography>
          <CloseBtn onClick={closeModal}>
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>

        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <Box>
              <ModalH6Text>Project name</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                }}
              >
                <img
                  src={listing.project.image}
                  style={{ borderRadius: '6px' }}
                  width={24}
                  height={24}
                  alt="project"
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: '13px', xsm: '15px' },
                    fontWeight: 400,
                    paddingLeft: '0.5rem',
                  }}
                >
                  {listing.project.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Current Price</ModalH6Text>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    paddingRight: '0.3rem',
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  <CurrentPriceBox>
                    {cryptoFormat(listing.project.currentPrice)}
                  </CurrentPriceBox>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <ModalH6Text>Asking Price</ModalH6Text>
              <CurrentPriceBox>{getModalAskingPrice(listing)}</CurrentPriceBox>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '15px',
            justifyContent: 'space-between',
            borderBottom: border1pxPrimaryLight,
            borderTop: border1pxPrimaryLight,
          }}
        >
          <Box sx={{ flex: { xs: 'none', xsm: 2.2 } }}>
            <ModalH6Text sx={{ marginBottom: '8px' }}>Listing User</ModalH6Text>
            <Link
              href={`${networkLink}${listing.user.ethAddress}`}
              target="_blank"
              sx={{ fontSize: { xs: '14px', xsm: '15px' } }}
              aria-label="wallet address"
            >
              {sliceWalletAddress(listing.user.ethAddress)}
            </Link>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2.1 } }}>
            <Box>
              <ModalH6Text>Token Amount</ModalH6Text>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '14px', xsm: '15px' },
                  paddingTop: '10px',
                }}
              >
                {formatNr(listing.amount, undefined, 0)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flex: { xs: 'none', xsm: 2 } }}>
            <Box>
              <ModalH6Text>Total Cost</ModalH6Text>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  fontSize: { xs: '14px', xsm: '15px' },
                  paddingTop: '10px',
                }}
              >
                {formatNr(listing.price, true)}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ p: '15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ModalH6Text mr={1}>Get paid in</ModalH6Text>
            <Tooltip
              title="You can choose one stable coin"
              placement="right-end"
            >
              <QuestionMarkStyled />
            </Tooltip>
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '0.7rem',
              paddingTop: '11px',
            }}
          >
            {listingCoinsData.map((coin) => (
              <Box
                key={coin.name}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ButtonGradientDark
                  variant={
                    values.selectedCoin === coin.name ? 'contained' : 'outlined'
                  }
                  onClick={() => handleSelectedCoin(coin)}
                  fullWidth
                  disableRipple
                >
                  {coin.name}
                </ButtonGradientDark>

                <Typography variant="h6" mt="8px">
                  {formatNr(coin.balance, true)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <ModalFooter>
          <SellGradientDark
            onClick={() => handleSubmit()}
            disabled={disabledSellButton || isLoading}
            sx={{
              padding: {
                xs: `${toRem(18)} ${toRem(16)}`,
                sm: `${toRem(11)} ${toRem(16)}`,
              },
            }}
          >
            Sell
          </SellGradientDark>
        </ModalFooter>
        {invalidCoins && (
          <ErrorListTooltip>
            <ErrorListItemTooltip>{errors.selectedCoin}</ErrorListItemTooltip>
          </ErrorListTooltip>
        )}
      </Box>
    </ModalCom>
  );
}

export default SellWTBModal;
