import { Box, Typography, Button, Link, useTheme } from '@mui/material';
import { TOffer } from '@typescript/models/Offer.model';
import CloseIcon from '@mui/icons-material/Close';
import { BuyGradientDark } from '@styles/shared/SButtons';
import useUtils from '@hooks/useUtils';
import {
  CloseBtn,
  HeaderModal,
  ModalDetailsBox,
  ModalFooter,
  ModalH4Text,
  ModalH6Text,
} from '@styles/modal/SModal';
import ModalCom from '@components/modals/ModalCom';
import { formatNr, sliceWalletAddress, toRem } from '@utils/functions';
import { border1pxPrimaryLight } from '@styles/shared/SCss';
import { calculateAmountAfterFee } from '@utils/calculateAmountAfterFee';
import useTransfer from '@hooks/useTransfer';
import { MetamaskError } from '@utils/errors';
import { TransferType } from '@constants/CTransfer';
import { useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  offer: TOffer;
  onSuccess: () => void;
  onError: (message: string) => void;
  onTxInitialization: () => void;
  beforeTxConfirmation: (hash: string) => void;
}
function MakeOfferTransactionModal({
  open,
  onClose,
  offer,
  onSuccess,
  onError,
  onTxInitialization,
  beforeTxConfirmation,
}: Props) {
  const { getBscAddressLink, transferType } = useUtils();

  const theme = useTheme();

  const { offerTransfer } = useTransfer();

  const [isLoading, setIsLoading] = useState(false);

  const { totalPrice: totalPriceWitFee, feeOfListingPrice } =
    calculateAmountAfterFee(+offer.offeredPrice);

  const totalPrice =
    transferType === TransferType.OTC_TRANSFER_CONTRACT
      ? totalPriceWitFee
      : offer.offeredPrice;

  const feeText =
    transferType === TransferType.OTC_TRANSFER_CONTRACT
      ? `${formatNr(feeOfListingPrice, true)} Fee`
      : '';

  const cancelButtonStyled = {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.text.primary,
    fontWeight: 400,
    border: border1pxPrimaryLight,
    padding: {
      xs: `${toRem(18)} ${toRem(16)}`,
      sm: `${toRem(11)} ${toRem(16)}`,
    },
    ':hover': {
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.text.primary,
    },
  };

  const makeOfferTransaction = async () => {
    try {
      setIsLoading(true);
      onTxInitialization();
      await offerTransfer(offer, beforeTxConfirmation);
      setIsLoading(false);
      onSuccess();
    } catch (e) {
      const error = e as MetamaskError;
      if (error?.code === 4001) {
        onError('Transaction rejected.');
      } else {
        onError('Something went wrong..');
      }
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <ModalCom
      open={open}
      onClose={() => {
        if (!isLoading) {
          onClose();
        }
      }}
    >
      <>
        <HeaderModal>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: '16px', xsm: '18px' } }}
          >
            Offer Transaction Confirmation
          </Typography>
          <CloseBtn
            onClick={() => {
              if (!isLoading) {
                onClose();
              }
            }}
          >
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </CloseBtn>
        </HeaderModal>
        <Box p="15px">
          <ModalDetailsBox>
            <Box
              p="15px 15px 14px"
              sx={{ borderBottom: border1pxPrimaryLight }}
            >
              <ModalH4Text>Summary</ModalH4Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  borderRight: border1pxPrimaryLight,
                  p: '15px',
                  minWidth: '133px',
                }}
              >
                <ModalH6Text>Project Name</ModalH6Text>
                <ModalH6Text>Amount</ModalH6Text>
                <ModalH6Text>Asked Price</ModalH6Text>
                <ModalH6Text>Total Listing Cost</ModalH6Text>
                <ModalH6Text>Offered Price</ModalH6Text>
                <ModalH6Text>Total Offer Cost</ModalH6Text>
                <ModalH6Text>Listing User</ModalH6Text>
                <ModalH6Text>Accepted Coin</ModalH6Text>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: '10px',
                  p: '15px',
                }}
              >
                <Box
                  sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
                >
                  <img
                    src={offer.listing.project.image}
                    style={{ borderRadius: '6px' }}
                    width={20}
                    height={20}
                    alt="project"
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 400,
                      fontSize: { xs: '13px', xsm: '15px' },
                    }}
                  >
                    {offer.listing.project.name}
                  </Typography>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(offer.listing.amount, undefined, 0)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(+offer.listing.tokenPrice, true)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(offer.listing.price, true)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(offer.offeredTokenPrice, true)}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {formatNr(+totalPrice, true)} {feeText}
                </Typography>
                <Link
                  href={getBscAddressLink(offer.listing.user.ethAddress)}
                  target="_blank"
                  aria-label="wallet address"
                >
                  {sliceWalletAddress(offer.listing.user.ethAddress)}
                </Link>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '13px', xsm: '15px' },
                  }}
                >
                  {offer.offerCoin}
                </Typography>
              </Box>
            </Box>
          </ModalDetailsBox>
        </Box>
        <ModalFooter
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: '12px',
          }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            fullWidth
            sx={{ ...cancelButtonStyled }}
          >
            Cancel
          </Button>
          <BuyGradientDark
            onClick={makeOfferTransaction}
            sx={{
              boxShadow: 'none',
              padding: {
                xs: `${toRem(18)} ${toRem(16)}`,
                sm: `${toRem(11)} ${toRem(16)}`,
              },
            }}
            disabled={isLoading}
          >
            Make Transaction
          </BuyGradientDark>
        </ModalFooter>
      </>
    </ModalCom>
  );
}

export default MakeOfferTransactionModal;
